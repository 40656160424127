import { FC, useState } from "react";
import "./styles.scss";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import beatsImage from "../../../assets/img/temporary-images/beat-image.svg";
import blueTik from "../../../assets/img/Profile-pics/Subtract.svg";
import PlaybackBtn from "../../../assets/img/play-btn/Beat-Playback-Btn.png";
import { UserLable } from "../../../common/user-labels";
import {
  DropworksDisableButton,
  DropworksSmallButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { MeatballsMenuDropdown } from "../../../common/icons";
import { viewType } from "./modal";
import { BlueTik} from "../../../common/all-icons";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { HeartIcon } from "../../../common/heart-icon";

export const view: FC<viewType> = ({ navigateTo }) => {
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Block",
    "Report",
  ]);

  return (
    <div className="beats-page container container-xxl min-height-cover beats-page-minheight">
      <div className="beats-page-body">
        <div>
          <img src={beatsImage} className="beats-page-image" alt="user-image" />
        </div>
        <div className="beats-page-content">
          <div className="w-100">
            <div className="beats-page-details">
              <Heading1 className="beats-page-name">Hot Summer</Heading1>
              <img
                src={PlaybackBtn}
                className="beats-page-playback-Btn uni-ml-24 cursor-pointer"
                alt="PlaybackBtn"
              />
            </div>
            <div className="beats-page-song-title">Unlost</div>
          </div>
          <div className="d-flex gap-3 mt-2 w-100">
            <UserLable>Beat</UserLable>
          </div>
        </div>
      </div>

      <div className="d-flex gap-3 uni-mt-mb-64 beats-page-bottons">
        <DropworksDisableButton onClick={navigateTo("/message-chat")}>Message</DropworksDisableButton>
        <ButtonComponent id="InviteModal"><DropworksSmallButton className="width-178">Invite</DropworksSmallButton></ButtonComponent>
        <HeartIcon/>
        <MeatballsMenuDropdown
          arr={MeatballsMenuOptions} 
          height={"9px"}
        ></MeatballsMenuDropdown>
      </div>
      <h4 className="beats-page-subheading uni-mb-16">Creator</h4>
      <div className="beats-page-sub-content mb-4 cursor-pointer" onClick={navigateTo("/user-profile")} >
        <img src={userImage} className="beats-page-sub-content-img" alt="" />
        <div className="beats-page-sub-details">
          <h5 className="beats-page-sub-song-name">Unlost</h5>
          <div className="uni-ml-8"><BlueTik /></div>
          <MeatballsMenuDropdown
          arr={MeatballsMenuOptions}
          height={"5px"}
        ></MeatballsMenuDropdown>
        </div>
      </div>
    </div>
  );
};
