import React, { useState } from "react";
import { FC } from "react";
import "./styles.scss";
import { IoSettingsSharp } from "react-icons/io5";
import { viewType } from "./modal";
import {
  TextfieldLG,
  TextfieldSM,
} from "../../../common/custom-field/dropstock-text";
import { FaChevronRight } from "react-icons/fa6";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";
import Tabs from "../../../common/tabs";
import settingicon from "../../../assets/steps/settingicon.svg";
import TabsComponent from "../../../common/tabs-component/view";
const view: FC<viewType> = ({ navigateTo }) => {
  const [currentSection, setCurrentSection] = useState("Announcements");
  const [status, setStatus] = useState("");
  const [showMore, setshowMore] = useState({
    showMore1: false,
    showMore2: false,
    showMore3: false,
    showMore4: false,
    showMore5: false,
    showMore6: false,
    showMore7: false,
    showMore8: false,
    showMore9: false,
    showMore10: false,
    showMore11: false,
    showMore12: false,
    showMore13: false,
  });

  const shortText =
    "Deposit successfully received successfully received successfully received successfully received successfully received successfully received successfully received successfully received ...";
  const longText =
    "Deposit successfully received successfully received successfully received successfully received successfully received successfully received successfully received successfully received ";
  const handleSectionClick = (section: string) => {
    setCurrentSection(section);
    setStatus("");
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  return (
    <div className="invite container-xl container-xxl container min-height-cover">
      <Heading2 className="uni-mb-56">
        Notifications{" "}
        <span>
          <img
            src={settingicon}
            className="uni-ml-16 uni-fs-24  cursor-pointer"
            onClick={navigateTo("/profile-setting")}
          />
        </span>
      </Heading2>

      <Tabs
        className="uni-mb-56"
        id="4"
        tabsTitle={[
          <div className="d-flex">
            Announcements <span className="notify-yellow-dot uni-ml-8 "></span>
          </div>,
          "Wallet",
          "Offers",
          "Marketplace",
          "Favorites",
        ]}
        checkTabState={checkTabState}
      />

      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received{" "}
                  <span className="notify-yellow-dot uni-ml-8"></span>
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore1 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3  ellipsis-icon   justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore1 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore1: !showMore.showMore1 })
                  }
                >
                  {showMore.showMore1 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>

            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore2 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore2 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore2: !showMore.showMore2 })
                  }
                >
                  {showMore.showMore2 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>

            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore3 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore3 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore3: !showMore.showMore3 })
                  }
                >
                  {showMore.showMore3 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
            <div className="uni-py-24 uni-fs-700 notify-section-2">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore4 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore4 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore4: !showMore.showMore4 })
                  }
                >
                  {showMore.showMore4 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
          </>,
          <>
            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore5 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore5 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore5: !showMore.showMore5 })
                  }
                >
                  {showMore.showMore5 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>

            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore6 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore6 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore6: !showMore.showMore6 })
                  }
                >
                  {showMore.showMore6 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
            <div className="uni-py-24 uni-fs-700 notify-section-2">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore7 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore7 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore7: !showMore.showMore7 })
                  }
                >
                  {showMore.showMore7 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
          </>,
          <>
            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore8 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore8 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore8: !showMore.showMore8 })
                  }
                >
                  {showMore.showMore8 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>

            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore9 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore9 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore9: !showMore.showMore9 })
                  }
                >
                  {showMore.showMore9 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
          </>,

          <>
            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore10 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore10 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore10: !showMore.showMore10 })
                  }
                >
                  {showMore.showMore10 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
          </>,
          <>
            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore11 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore11 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore11: !showMore.showMore11 })
                  }
                >
                  {showMore.showMore11 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>

            <div className="uni-py-24 uni-fs-700 notify-section">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore12 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore12 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore12: !showMore.showMore12 })
                  }
                >
                  {showMore.showMore12 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
            <div className="uni-py-24 uni-fs-700 notify-section-2">
              <p className="d-flex justify-content-between uni-mb-8">
                <p className="d-flex align-items-center notify-subheading  justify-content-start">
                  Deposit Received
                </p>
                <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
              </p>
              <p className="d-flex justify-content-between uni-mb-8">
                <p>
                  {!showMore.showMore13 && (
                    <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
                      {shortText}
                    </p>
                  )}
                  {showMore.showMore13 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
                      return (
                        <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                          {longText}
                        </p>
                      );
                    })}
                </p>
                <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
                  <FaChevronRight
                    className="cursor-pointer"
                    onClick={navigateTo("/wallet")}
                  />
                </p>
              </p>
              <p className="d-flex ">
                <span
                  className="notify-show-more uni-fw-400 "
                  onClick={() =>
                    setshowMore({ ...showMore, showMore13: !showMore.showMore13 })
                  }
                >
                  {showMore.showMore13 ? "Show Less" : "Show More"}
                </span>
              </p>
            </div>
          </>,
        ]}
      />
    </div>
  );
};

export default view;
