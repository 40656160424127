import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import { CloseModalButton } from '../../logo-header/view';

const view = () => {
  return (
    <div
    className="modal fade"
    id="LoginHistorySocials"
    aria-hidden="true"
    aria-labelledby="LoginHistorySocials"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width ">
      <div className="modal-content mx-auto modal-width-login-history">
      <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Login History</h1>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
          <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-32">Check for suspicious activity</TextfieldLG>

          <p className='uni-p-16 border-bottom'>
          <TextfieldMD className='mx-0 my-0 text-field-login-history-first'>Last Logins</TextfieldMD>
          </p>
          <p className='uni-p-16 border-bottom'>
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p>
          <p className='uni-p-16 border-bottom' >
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p>
          <p className='uni-p-16 border-bottom'>
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p>
          <p className='uni-p-16 border-bottom'>
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p>
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <CloseModalButton>
          <DropworksSmallButton  className="btn-wid m-0">Confirm</DropworksSmallButton>
          </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

