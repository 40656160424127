import './styles.scss'
import { viewType } from "./modal";
import { FC } from "react";
import { Heading2 } from '../../../../common/custom-field/dropstock-heading';
const view:FC<viewType> = ({ navigateTo }) => {
  return (
    <div className='create container'>
        <Heading2 className='create-heading uni-mb-32'>Create</Heading2>
        <div className='create-content'>
            <div className='create-content-section1 uni-mb-40 cursor-pointer' onClick={navigateTo("/beat-empty")}><Heading2 className='uni-ml-56 uni-mb-24'>Add a <span>BEAT</span> to my library </Heading2></div>
            <div className='create-content-section2 uni-mb-40 cursor-pointer' onClick={navigateTo("/track-project-overview")}><Heading2 className='text-end uni-mr-56  uni-mb-24'>Create a new <span>TRACK</span></Heading2></div>
            <div className='create-content-section3 uni-mb-40 cursor-pointer' onClick={navigateTo("/track-project-overview2")}><Heading2 className='uni-ml-56  uni-mb-24'>Add an already <span>RELEASED TRACK</span> to DropWorks</Heading2></div>
        </div>
    </div>
  )
}

export default view