import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
    className="modal fade"
    id="EmailModal"
    aria-hidden="true"
    aria-labelledby="EmailModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
<div className="modal-dialog modal-dialog-centered custom-modal-deposit">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header  ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Change Email Address</h1>
        </div>
        
        <div className="modal-body  ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 uni-mb-56 ctm-txt">To proceed please confirm your identity </TextfieldLG>
          <div className='d-flex investor-box-parent-deposit uni-mb-40 uni-mt-8 '>
               <div className='investor-boxs-deposit'>
                    <p className='investor-boxs-under-modal2'>Email</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs-deposit'>
                    <p className='investor-boxs-under-modal2'>SMS</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
          </div>
        </div>  
        
        <div className="modal-footer  ps-0 pt-0 pb-0 uni-pr-72">
        <ButtonComponent id="EmailModal1">
        <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
        </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

