import { FC, useState } from "react";
import "./styles.scss";
import { UserIcon } from "../../../common/user-icon";
import { TrackIcon } from "../../../common/track-icon";
import { BeatesIcon } from "../../../common/beats-icon";
import { DropworksDropdown } from "../../../common/custom-field/dropworks-dropdown";
import { ProjectIcon } from "../../../common/project-icon";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import blueTik from "../../../assets/img/Profile-pics/Subtract.svg";
import backBtn from "../../../assets/img/icons/backBtn.svg";
import { viewType } from "./modal";
import { UserLable } from "../../../common/user-labels";
import {
  DropworksButton,
  DropworksDisableButton,
  DropworksSmallButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { ArrowDropdown, ArrowDropdownGray4, MeatballsMenuDropdown } from "../../../common/icons";
import { SelectArrowDropdown } from "../../../common/selection-dropdown";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import CustomTooltip from "../../../common/custom-tooltip/view";
import { BlueTik } from "../../../common/all-icons";
import { TextfieldXSM } from "../../../common/custom-field/dropstock-text";

export const view: FC<viewType> = ({ navigateTo }) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    setIsFollowing((prev) => !prev);
  };

  const handleMouseEnter = () => {
    if (isFollowing) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [userarr, setUserarr] = useState([
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
  ]);

  const [dropdownarrRoles, setDropdownarrRoles] = useState({
    heading: "Roles",
    option: [
      "Investor",
      "Producer",
      "Vocalist",
      "Lyricist",
      "Beat Maker",
      "Curator",
      "Artwork Designer",
    ],
  });
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState(
    [
      "Block",
      "Report",
    ]
  );

  const [dropdownarrTracks, setDropdownarrTracks] = useState({
    heading: "Genre",
    option: ["Genre", "Genre", "Genre", "Genre", "Genre", "Genre", "Genre"],
  });
  const [trackarr, setTrackarr] = useState([
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [projectarr, setProjectarr] = useState([
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [beatsarr, setBeatsarr] = useState([
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
  ]);

  const [button, setButton] = useState("user-profile");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };
  const options = [
    { value: 'Spotify', label: 'Spotify' },
    { value: 'Apple Music', label: 'Apple Music' },
    { value: 'Deezer', label: 'Deezer' },
    { value: 'Tidal', label: 'Tidal' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Youtube', label: 'Youtube' },
  ]


  return (
    <div className="user-profile container container-xxl min-height-cover">
      <div className="user-profile-body">
        <div>
          <img
            src={userImage}
            className="user-profile-image"
            alt="user-image"
          />
        </div>
        <div className="user-profile-content">
          <div className="w-100">
            <div className="user-profile-details">
              <Heading3 className="uni-mr-8">King Roland</Heading3>
              <CustomTooltip title="Verified account." className="w-auto" position="right">
                <BlueTik />
              </CustomTooltip>
            </div>
            <div className="user-profile-id">@kingroland</div>
          </div>
          {button === "user-profile" ? (
            <>
              <div className="d-flex flex-wrap gap-3 mt-2">
                <UserLable className="user-label-style">Investor</UserLable>
                <UserLable>Producer</UserLable>
                <UserLable>Vocalist</UserLable>
                <UserLable>Lyricist</UserLable>
                <UserLable>Beat Maker</UserLable>
                <UserLable>Curator</UserLable>
                <UserLable>Artwork Designer</UserLable>
                <UserLable>FL Studio</UserLable>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      {button === "user-profile" ? (
        <>
          <div className="d-flex gap-3  uni-mt-32 user-profile-bottons">
            <DropworksDisableButton
              className={`follow-button user-profiles-follow-buttons ${isFollowing ? "following" : "follow"} ${isHovered && isFollowing? "hover" : ""
                }`}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >  {isFollowing ? (isHovered ? "Unfollow" : "Following") : "Follow"}</DropworksDisableButton>
            {/* please add dropdown */}
            <DropworksSmallButton className="user-profiles-select-buttons uni-bg-disable"> 

              <TextfieldXSM className="m-0">Socials</TextfieldXSM>
              <ArrowDropdownGray4 pagename="user-profile" 
                                className="px-0 "
                                arr={["Spotify", "Apple Music", "Tidal", "Deezer","Instagram","X","Youtube"]}
                              />
            
              
              </DropworksSmallButton>
            <DropworksSmallButton  className="user-profiles-Message-buttons uni-bg-disable" onClick={navigateTo("/message-chat2")}>Message</DropworksSmallButton >

            <ButtonComponent id="InviteModal">  <DropworksSmallButton className="invite-btn-w user-profiles-invite-buttons">Invite</DropworksSmallButton></ButtonComponent>

            <MeatballsMenuDropdown arr={MeatballsMenuOptions}
              height={"9px"}></MeatballsMenuDropdown>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="user-profile-button d-flex flex-wrap mb-4">
        {button === "tracks" || button === "beats" ? (
          <>
            <button
              className={`user-profile-button-backBtn`}
              onClick={() => handleButtonClick("user-profile")}
            >
              <img
                className="user-profile-button-image"
                src={backBtn}
                alt="backBtn"
              />
            </button>
            <button
              className={`user-profile-button-user-profile ${button === "tracks" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("tracks")}
            >
              Tracks
            </button>
            <button
              className={`user-profile-button-user-profile ${button === "beats" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("beats")}
            >
              Beats
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      {button === "user-profile" ? (
        <>
          <div className="user-profile-subheading uni-mt-56 ">Biography</div>
          <div className="user-profile-para uni-mb-56">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            tempor lorem ac tellus egestas, quis lobortis lorem condimentum.
            Phasellus lacinia blandit quam, nec posuere neque blandit id.
            Pellentesque venenatis justo eu libero luctus hendrerit vel et
            tortor. Nullam metus enim, luctus et{" "}
          </div>
        </>
      ) : null}

      {button === "user-profile" || button === "tracks" ? (
        <>
          {button === "tracks" ? (
            <div className="user-profile-subheading">Tracks</div>
          ) : (
            <div
              className="user-profile-subheading-link"
              onClick={() => handleButtonClick("tracks")}
            >
              Top Tracks
            </div>
          )}

          <div className="user-profile-usericon">
            <TrackIcon arr={trackarr} />
          </div>
        </>
      ) : null}
      {button === "user-profile" || button === "tracks" ? (
        <>
          {button === "tracks" ? (
            ""
          ) : (
            <div
              className="user-profile-subheading-link"
              onClick={() => handleButtonClick("tracks")}
            >
              Recent Releases
            </div>
          )}

          <div className="user-profile-usericon">
            <TrackIcon arr={trackarr} />
          </div>
        </>
      ) : null}

      {button === "user-profile" || button === "beats" ? (
        <>
          {button === "beats" ? (
            <div className="user-profile-subheading-link">Beats</div>

          ) : (
            <div
              className="user-profile-subheading"
              onClick={() => handleButtonClick("tracks")}
            >
              Beats
            </div>
          )}
          <div className="user-profile-usericon">
            <BeatesIcon arr={beatsarr} />
          </div>
        </>
      ) : null}
    </div>
  );
};
