import {configureStore} from '@reduxjs/toolkit';

import { authSlice } from './reducer/auth';
import { musicReducer } from './reducer/musicSlice';
import { dropWorksSelectReducer } from './reducer/dropworksSelect';
import { uploadReducer } from './reducer/uploadSlice';
// import {uiSlice} from '@app/store/reducers/ui';
// import {createLogger} from 'redux-logger';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    music: musicReducer,
    dropWorksSelectOption:dropWorksSelectReducer,
    upload:uploadReducer
    // ui: uiSlice.reducer
  },
//   middleware: (getDefaultMiddleware) => [
//     ...getDefaultMiddleware().concat(createLogger())
//   ]
});
export type RootState = ReturnType<typeof store.getState>;
export default store;