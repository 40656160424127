import { FC, useState } from "react";
import "./styles.scss";
import { AiOutlineDown } from "react-icons/ai";

// Sample country data
const countryData = [
  { name: "Portugal", code: "+351", flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg" },
  { name: "Albania", code: "+355", flag: "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg" },
  { name: "Algeria", code: "+213", flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg" },
  { name: "Andorra", code: "+376", flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg" },
  { name: "Angola", code: "+244", flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg" },
  { name: "Anguilla", code: "+264", flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg" }
];

interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {}

export const view: FC<CustomDivProps> = ({ ...props }) => {
  const [selectedCountry, setSelectedCountry] = useState(countryData[0]);

  const handleCountrySelect = (country: typeof countryData[0]) => {
    setSelectedCountry(country);
  };

  return (
    <div className={`${props.className} dropdown cursor-pointer`} >
      <div
        className="country-selector dropdown-toggle"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src={selectedCountry.flag} alt={`${selectedCountry.name} Flag`}  className="flag-img"/>
        <span className="code">{selectedCountry.code}</span>
        <AiOutlineDown className="dropdown-icon" />
      </div>
      <ul className="dropdown-menu dropdown-ul" aria-labelledby="dropdownMenuButton">
        {countryData.map((country) => (
          <li
            key={country.name}
            className="dropdown-li"
            onClick={() => handleCountrySelect(country)}
          >
            {country.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
