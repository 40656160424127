import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksDisableButton, DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import { CloseModalButton } from "../../logo-header/view";

const view = () => {

  const options = [
    { value: "Spotify", label: "Spotify" },
    { value: "Apple Music", label: "Apple Music" },
    { value: "Deezer", label: "Deezer" },
    { value: "Tidal", label: "Tidal" },
    { value: "Instagram", label: "Instagram" },
    { value: "Youtube", label: "Youtube" },
  ];
  return (
    <div
      className="modal fade"
      id="RegionModal"
      aria-hidden="true"
      aria-labelledby="RegionModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Region</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh">
              Select your region
            </TextfieldLG>
            <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
              We will use this to give you the best suggestions
            </TextfieldXSM>
            {/* <DropworksSelect className="uni-mb-56" /> */}
            {/* <DropworksSelect className="uni-mb-56">
              <option value="">Region</option>
            </DropworksSelect> */}
            <DropworksDisableButton className="custom-select-dropdown uni-mb-56">
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Select"
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid m-0">
              Save
            </DropworksSmallButton>
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;

