import { FC } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../../common/custom-field/dropworks-textArea";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
import { Heading2, Heading3 } from "../../../../common/custom-field/dropstock-heading";
const view: FC<viewType> = ({ navigateTo }) => {
  return (
    <div className="settingUp row align-items-pages container pb-3 m-auto h-100  justify-content-between px-0  min-h-800">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 px-0 settingUp_form">
          <Heading3 className="settingUp_heading my-0">
            Setting up your profile
          </Heading3>
          <div className="signUp_normal uni-my-32  custom-text-sm">
            Please fill out all the fields
          </div>
          <form>
            <DropworksInputIcon placeholder="Username" tooltipTitle="This will be your unique username that will be permanently tied to your account." />
            <DropworksInputIcon placeholder="Display Name" tooltipTitle="This is the name that people will know you for. Can be changed in the settings." />
            <DropworksTextArea placeholder="Biography (optional)" rows={4} />
          </form>
          <ButtonComponent id="Modal1">
          <DropworksButton>Finish</DropworksButton>
          </ButtonComponent>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 mt-5 mt-sm-0 px-0">
          <div className="settingUp_uploadbox flex-column uni-mb-40 ml-auto">
              <img src={iconImg} />
              <label className="mt-4"> Profile Picture</label>
          </div>
          <div className="w-476  ml-auto">
            <DropworksButton className="settingUp_register_finish">
              Randomize
            </DropworksButton>
          </div>
        </div>
    </div>
  );
};
export default view;
