import React, { FC, useState } from "react";
import "./styles.scss";
import heartIcon from '../../assets/steps/newheartimg.svg';
import fillheartIcon from '../../assets/steps/newheartfill.svg';

export const view: FC<any> = ({className}) => {
    const [isFilled, setIsFilled] = useState(false);

    const handleClick = () => {
        setIsFilled(prev => !prev);
    };
    return (
        
        <img src={isFilled ? fillheartIcon : heartIcon} alt="Heart Icon" onClick={handleClick}   className={`cursor-pointer custom-heart-icon ${isFilled ? "is-filled" : ""}`}/>
    );
};
