import React, { FC, useState } from "react";
import "./styles.scss";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { RiQuestionFill } from "react-icons/ri";
import CustomTooltip from "../../custom-tooltip/view";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  showHide: () => void;
  show: boolean;
  tooltipTitle: string;
}

export const view: FC<InputProps> = ({
  className = "",
  show,
  tooltipTitle,
  showHide,
  placeholder = "Password",
  ...rest
}) => {
  return (
    <div className="custom_inp_icon_container">
      <input
        className={`custom_inp_icon ${className}`}
        {...rest}
        type={show ? "text" : "text"}
        placeholder={placeholder}
      />
      <span className="question-icon">
      <CustomTooltip title={tooltipTitle} position="right">
          <RiQuestionFill className="hover-icon-color" onClick={() => showHide()} />
          </CustomTooltip>
      </span>
    </div>
  );
};
