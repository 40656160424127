import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';

import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import checkCircle from "../../../../../assets/steps/check-circle.svg"
import { ButtonComponent, CloseModalButton } from '../../logo-header/view';

const view = () => {
  return (
    <div
      className="modal fade"
      id="SocialLinksMain"
      aria-hidden="true"
      aria-labelledby="SocialLinksMain"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content modal-width-notification">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Social Links</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh">Add you socials to DropWorks</TextfieldLG>
            <TextfieldXSM className='uni-mb-56 sn-ctm-txt'>We will display these on your profile</TextfieldXSM>

            <ButtonComponent id="SocialLink8">
              <div className='uni-p-16 social-links-main-hover'>
                <TextfieldSM className='mx-0 my-0 text-field-notification'>Spotify</TextfieldSM>
              </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink14">
            <div className='uni-p-16 social-links-main-hover'>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Apple Music</TextfieldSM>
            </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink9">
            <div className='uni-p-16 social-links-main-hover d-flex justify-content-between'>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Tidal</TextfieldSM>
              <img src={checkCircle} />
            </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink13">
            <div className='uni-p-16 social-links-main-hover'>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Deezer</TextfieldSM>
            </div>
            </ButtonComponent>
            <ButtonComponent id="SocialLink10">
            <div className='uni-p-16 social-links-main-hover'>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Instagram</TextfieldSM>
            </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink12">
            <div className='uni-p-16 social-links-main-hover'>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>X</TextfieldSM>
            </div>
            </ButtonComponent>
            <ButtonComponent id="SocialLink11">
            <div className='uni-p-16 social-links-main-hover'>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Youtube</TextfieldSM>
            </div>
            </ButtonComponent>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid m-0">Save</DropworksSmallButton>
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

