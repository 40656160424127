import React, { FC, useState, useRef, useEffect } from "react";
import "./styles.scss";
import meatballsIcon from "../../assets/img/icons/meatballs-menu-icon.svg"
import { ButtonComponent } from "../../app/shared/components/logo-header/view";
import { Link } from "react-router-dom";

interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
  arr: string[];
  height?: string;
  icon?: any;
  className?: string;
  pagename?: string;
}
export const view: FC<DropdownProps> = ({ arr, icon = meatballsIcon, className, height, pagename }) => {

  return (
    <div className='dropdown d-flex' onClick={(e: any) => e.stopPropagation()} >
      <div
        tabIndex={2}
        className={`meatballs-menu  dropdown-toggle ${className}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >

        <img src={icon} className={`meatballs-menu-image `} style={{ height: `${height}` }} alt="img" />

      </div>
      <ul className="dropdown-menu dropdown-menu-meatballs-Icon p-0">
        {arr.map((data) => {
          if (pagename === "user-profile") {
            return (
              <li key={data} className="dropdown-menu-meatballs-Icon-li">
                <Link className="dropdown-item dropdown-item-meatballs" to={data === "Spotify" ? "https://open.spotify.com/" : data === "Apple Music" ? "https://music.apple.com/us/artist/web-player/1559317097" : data === "Tidal" ? "https://tidal.com/browse/artist/24034301" : data === "Deezer" ? "https://www.deezer.com/soon" : data === "Instagram" ? "https://www.instagram.com" : data === "X" ? "https://x.com/home" : data === "Youtube" ? "https://www.youtube.com/" : ""}>
                  {data}
                </Link>
              </li>
            );
          } else {
            return (
              <ButtonComponent
                id={
                  data === "Report"
                    ? "ReportModal1"
                    : data === "Block"
                      ? "BlockedModal"
                      : data === "Change Alias"
                        ? "ChangeAlias"
                        : data === "Remove"
                          ? "RemoveCollaborator"
                          : data === "Delete"
                            ? pagename === "message"
                              ? "DeleteChat"
                              : "DeleteFile"
                            : data === "Approve"
                              ? "ApproveFile"
                              : data === "Decline"
                                ? "DeclineFile"
                                : ""
                }
              >
                <li key={data} className="dropdown-menu-meatballs-Icon-li">
                  <a className="dropdown-item dropdown-item-meatballs" href="#">
                    {data}
                  </a>
                </li>
              </ButtonComponent>
            );
          }
        })}
      </ul>
    </div>
  );
};

