import "./styles.scss";
import { FC, useEffect, useState, useRef } from "react";
import Plyr from "plyr"; // Ensure you have installed plyr library
import "plyr/dist/plyr.css"; // Import Plyr's CSS
import {
  CrossCircle,
  LeftNextBtn,
  MuteIcon,
  PlayerPauseBtn,
  RightNextBtn,
  VolumeUpIcon,
} from "../../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../../common/icons";
import trackImage from "../../../../assets/img/temporary-images/track-image.svg";
import { useDispatch } from "react-redux";
import { setData } from "../../../../redux/reducer/musicSlice";
import { HeartIcon } from "../../../../common/heart-icon";
import Playercross from "../../../../assets/steps/audioplayercross.svg";
import { TextfieldXSM } from "../../../../common/custom-field/dropstock-text";
import crossIcon from '../../../../assets/steps/modal-cross-icon.svg';
// Define Track type
interface Track {
  track: number;
  name: string;
  duration: string;
  file: string;
}

// Track list
const tracks: Track[] = [
  {
    track: 1,
    name: "All This Is - Joe L.'s Studio",
    duration: "2:46",
    file: "JLS_ATI",
  },
  // Add all other tracks here
  //...
];

const mediaPath = "https://archive.org/download/mythium/";
const extension = ".mp3"; // Adjust if needed
export const view: FC = () => {
  const [playing, setPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [npAction, setNpAction] = useState("Paused...");
  const audioRef = useRef<HTMLAudioElement>(null);
  const plyrRef = useRef<Plyr | null>(null);
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState(["Report"]);

  const [volume, setVolume] = useState('75');
  const [mute, setMute] = useState(true);

  const handleVolumeChange = (e: any) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    setMute(true)
  };

  const handleMute = () => {
    setMute((muted) => !muted);
  };
  console.log(mute, "this is my mute buton");

  useEffect(() => {
    if (audioRef.current) {
      plyrRef.current = new Plyr(audioRef.current, {
        controls: [
          "restart",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "download",
        ],
      });

      const audioElement = audioRef.current;

      audioElement.addEventListener("play", () => {
        setPlaying(true);
        setNpAction("Now Playing...");
      });

      audioElement.addEventListener("pause", () => {
        setPlaying(false);
        setNpAction("Paused...");
      });

      audioElement.addEventListener("ended", () => {
        setNpAction("Paused...");
        if (currentTrackIndex + 1 < tracks.length) {
          setCurrentTrackIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            loadTrack(newIndex);
            audioElement.play();
            return newIndex;
          });
        } else {
          setCurrentTrackIndex(0);
          loadTrack(0);
          audioElement.pause();
        }
      });
    }
  }, [currentTrackIndex]);

  const loadTrack = (index: number) => {
    if (audioRef.current) {
      const audioElement = audioRef.current;
      const track = tracks[index];
      audioElement.src = `${mediaPath}${track.file}${extension}`;
      setNpAction("Now Playing...");
      // Update the download link
      plyrRef.current?.on("loadedmetadata", () => {
        const downloadLink = document.querySelector('a[data-plyr="download"]');
        if (downloadLink) {
          (downloadLink as HTMLAnchorElement).href = audioElement.src;
        }
      });
    }
  };

  const handlePrev = () => {
    if (currentTrackIndex > 0) {
      setCurrentTrackIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        loadTrack(newIndex);
        if (playing) audioRef.current?.play();
        return newIndex;
      });
    } else {
      setCurrentTrackIndex(0);
      loadTrack(0);
    }
  };

  const handleNext = () => {
    if (currentTrackIndex + 1 < tracks.length) {
      setCurrentTrackIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        loadTrack(newIndex);
        if (playing) audioRef.current?.play();
        return newIndex;
      });
    } else {
      setCurrentTrackIndex(0);
      loadTrack(0);
    }
  };
  const dispatch = useDispatch();
  return (
    <div className="palyer container-fluid">
      <div className="palyer-nowPlay col-4">
        <div className="d-flex align-items-center gap-3">
          <div>
            <img
              src={trackImage}
              className="palyer-song-image"
              alt="user-image"
            />
          </div>
          <div className="player-content">
            <div className="w-100">
              <div className="player-details">
                <h6 className="player-name">Hot Summer</h6>
              </div>
              <div className="player-song-title">
                King Roland, Mabapupi Ocachino,Kahino, Lala Baby
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 track-page-bottons">
            <HeartIcon className="custom-audio-heart-icon" />
            <MeatballsMenuDropdown
              arr={MeatballsMenuOptions}
              height={"6px"}
              className="meatballsplayer"
            ></MeatballsMenuDropdown>
          </div>
        </div>

        {/* <span id="npAction">{npAction}</span>
          <span id="npTitle">{tracks[currentTrackIndex]?.name || ""}</span> */}
      </div>
      <div className="col-4">
        <div className="d-flex justify-content-center align-items-baseline gap-2">
          <div className="cursor-pointer">
            <LeftNextBtn />
          </div>
          <div className="cursor-pointer">
            <PlayerPauseBtn />
          </div>
          <div className="cursor-pointer">
            <RightNextBtn />
          </div>
        </div>
        <div className="player-controls gap-3">
          <TextfieldXSM className="m-0 play-time">2:46</TextfieldXSM>
          <div
            className="progress cursor-pointer"
            style={{ height: "4px", width: "100%" }}
          >
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "75%" }}
              // aria-valuenow="25"
              // aria-valuemin="0"
              // aria-valuemax="100"
            ></div>
          </div>
          <TextfieldXSM className="m-0 play-time">3:16</TextfieldXSM>
        </div>
      </div>

      <div className="col-4 d-flex justify-content-end h-100">
        <div className="d-flex align-items-center gap-3">
          <div className="cursor-pointer">
            {mute && !(volume==='0') ?(
              <div onClick={handleMute}>
                <VolumeUpIcon />
              </div>
            ) : (
              <div onClick={handleMute}>
                <MuteIcon />
              </div>
            )}
          </div>
          <input
            type="range"
            color="red"
            min="0"
            max="100"
            value={mute ? volume :0}
            onChange={handleVolumeChange}
            className="cursor-pointer uni-mr-32"
            style={{ height: "3px", width: "81px" }}
          />
          <div onClick={() => dispatch(setData(false))}>
            <img src={crossIcon} className="cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
};
