import React, { FC } from "react";
import "./styles.scss";
import playmusic from "../../assets/img/play-music-icon.png"
export const view: FC<any> = ({
  navigateTo,
  BeatesIcon,
  arr
}) => {
  return (
    <div className="row">
      {arr.map((data: any, index: any) => (
        <div className="col-6 col-sm-6 col-md-3 col-lg-2  BeatesIcon-icon" onClick={navigateTo("/beats-page")}>
          <div className="BeatesIcon-icon-box">
            <img className="BeatesIcon-icon-box-icon" src={playmusic} />
          </div>
          <div className="BeatesIcon-icon-heading">{data.heading}</div>
          <div className="BeatesIcon-icon-subheading">{data.subheading}</div>
        </div>
      ))}
    </div>
  );
};
