import { FC, useState } from "react";
import "./styles.scss";
import { DropworksSearchInput } from "../../../../common/custom-field/dropworks-search-input";
import { DropworksDropdown } from "../../../../common/custom-field/dropworks-dropdown";
import { UserIcon } from "../../../../common/user-icon";
import { TrackIcon } from "../../../../common/track-icon";
import { ProjectIcon } from "../../../../common/project-icon";
import { BeatesIcon } from "../../../../common/beats-icon";
import searchIcon from "../../../../assets/img/searchIcon.svg";
import { Heading2 } from "../../../../common/custom-field/dropstock-heading";
import Tabs from "../../../../common/tabs";
import TabsComponent from "../../../../common/tabs-component/view";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoCloseCircleOutline } from "react-icons/io5";

export const view: FC = () => {
  const [userarr, setUserarr] = useState([
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
  ]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRoles1, setSelectedRoles1] = useState<string[]>([]);
  const [dropdownarrRoles, setDropdownarrRoles] = useState({
    heading: "Roles",
    option: [
      "Investor",
      "Producer",
      "Vocalist",
      "Lyricist",
      "Beat Maker",
      "Curator",
      "Artwork Designer",
    ],
  });

  const [dropdownarrTracks, setDropdownarrTracks] = useState({
    heading: "Genre",
    option: ["Genre1", "Genre2", "Genre3", "Genre4", "Genre", "Genre6", "Genre7"],
  });
  const [trackarr, setTrackarr] = useState([
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [projectarr, setProjectarr] = useState([
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [beatsarr, setBeatsarr] = useState([
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
  ]);

 
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const handleRoleChange = (role: string) => {
    setSelectedRoles((prev) =>prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]);
};
const handleRoleChange1 = (role: string) => {
  setSelectedRoles1((prev) =>prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]);
};
  return (
    <div className="search container container-xxl min-height-cover">
      <Heading2 className="mx-0 my-0 ">Favorites</Heading2>
      {tabsValue === 4 ? (
        <DropworksSearchInput className="search-inputbox uni-my-40" />
      ) : (
        ""
      )}

      <Tabs
        id="12"
        tabsTitle={[
          "Users",
          "Tracks",
          "Projects",
          "Beats",
          <img src={searchIcon} alt="Image Tab" />,
        ]}
        className="uni-mb-40 uni-mt-40"
        checkTabState={checkTabState}
      />

      <TabsComponent
        tabsValue={tabsValue}
        
        components={[
          <>
            <div className="uni-mb-16">
            <div className="dropworks-dropdown">
            <button
                className="dropworks-dropdown-button dropdown-toggle  uni-fw-700"
                type="button"
                id="dropdownMenuClickableInside"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                {dropdownarrRoles.heading} <RiArrowDropDownLine className="fs-4" />
            </button>

            <ul className="dropdown-menu dropworks-dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                {dropdownarrRoles.option.map((data) => (
                    <li key={data}>
                        <label className="dropdown-item d-flex align-items-center justify-content-between dropworks-dropdown-menu-option ">
                            <span className="text-wrap">{data}</span>
                            <input
                                type="checkbox"
                                name="role"
                                value={data}
                                checked={selectedRoles.includes(data)}
                                onChange={() => handleRoleChange(data)}
                                className="custom-checkbox me-2"
                            />
                            <span className="custom-radio"></span>
                        </label>
                    </li>
                ))}
            </ul>

            {selectedRoles.map((role) => (
                <button key={role} className="dropworks-dropdown-button">
                    {role}{" "}
                    <IoCloseCircleOutline onClick={() => handleRoleChange(role)} className="dropworks-dropdown-button-icon" />
                </button>
            ))}
        </div>
            </div>
            <div className="search-usericon">
              <UserIcon arr={userarr} />
            </div>
          </>,
          <>
           <div className="uni-mb-16">
           <div className="dropworks-dropdown">
            <button
                className="dropworks-dropdown-button dropdown-toggle  uni-fw-700"
                type="button"
                id="dropdownMenuClickableInside"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                {dropdownarrTracks.heading} <RiArrowDropDownLine className="fs-4" />
            </button>

            <ul className="dropdown-menu dropworks-dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                {dropdownarrTracks.option.map((data) => (
                    <li key={data}>
                        <label className="dropdown-item d-flex align-items-center justify-content-between dropworks-dropdown-menu-option ">
                            <span className="text-wrap">{data}</span>
                            <input
                                type="checkbox"
                                name="role"
                                value={data}
                                checked={selectedRoles1.includes(data)}
                                onChange={() => handleRoleChange1(data)}
                                className="custom-checkbox me-2"
                            />
                            <span className="custom-radio"></span>
                        </label>
                    </li>
                ))}
            </ul>

            {selectedRoles1.map((role) => (
                <button key={role} className="dropworks-dropdown-button">
                    {role}{" "}
                    <IoCloseCircleOutline onClick={() => handleRoleChange1(role)} className="dropworks-dropdown-button-icon" />
                </button>
            ))}
        </div>
            </div>
            <div className="search-usericon">
            <TrackIcon arr={trackarr} />
            </div>
          </>,
          <>
            <div className="search-usericon">
              <ProjectIcon arr={projectarr} />
            </div>
          </>,
          <>
            <div className="search-usericon">
          <BeatesIcon arr={beatsarr} />
            </div>
          </>,
          <>
          <div className="search-usericon">
          <TrackIcon arr={beatsarr} />
          </div>
          </>,
        
          
        ]}
      />
    </div>
  );
};
