import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadSlice {
   Data:any
  }

const initialState : UploadSlice = {
  Data: "", 
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setUploadData: (state, action: PayloadAction<any>) => {
      state.Data = action.payload; 
    }
  },
});


export const { setUploadData } = uploadSlice.actions;


export const uploadReducer = uploadSlice.reducer;