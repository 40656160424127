import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';

import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import { ButtonComponent, CloseModalButton } from '../../logo-header/view';

const view = () => {
  return (
    <div
      className="modal fade"
      id="SearchInvite"
      aria-hidden="true"
      aria-labelledby="SearchInvite"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Invitation</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh">Please use the search bar to find the user you want to invite</TextfieldLG>
            <TextfieldXSM className='uni-mb-56 search-input-display'>You may search for his display name or by his username</TextfieldXSM>
            <ButtonComponent id="InviteModal8">
            <DropworksSearchInput></DropworksSearchInput>
            </ButtonComponent>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
            <CloseModalButton>
            <DropworksDisableButton className="btn-wid search-invite-cancel my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksDisableButton>
            </CloseModalButton>
            <DropworksDisableButton className="btn-wid m-0  search-invite-upload custom-btn-upload">
              Save
            </DropworksDisableButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

