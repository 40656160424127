import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksDisableButton, DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import { CloseModalButton } from "../../logo-header/view";

const view = () => {

  const options = [
    { value: "Hindi", label: "Hindi" },
    { value: "English", label: "English" },
    { value: "Chines", label: "Chines" },
    { value: "Russian", label: "Russian" },
  ];
  return (
    <div
      className="modal fade"
      id="DisplayLanguageModal"
      aria-hidden="true"
      aria-labelledby="DisplayLanguageModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Display Language</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">
              Select your preferred language
            </TextfieldLG>
            <DropworksDisableButton className="custom-select-dropdown uni-mb-56">
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Language"
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid m-0">
              Save
            </DropworksSmallButton></CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;


