import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';

import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent, CloseModalButton } from '../../logo-header/view';
import { useState } from 'react';

const view = () => {
  const [select, setSelect] = useState("");

  const changeSelect = (value: string) => {
    console.log(value)
    setSelect(value);
    
  };
  return (
    <div
      className="modal fade"
      id="ReportModal1"
      aria-hidden="true"
      aria-labelledby="ReportModal1"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
            <h2 className="modal-title-fs-32">What do you want to report?</h2>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <p className='d-flex justify-content-between uni-p-16 p-lh uni-mb-8' onClick={()=>{changeSelect("SelectTrack")}}>

              <TextfieldSM className=" font-col-wh my-0 mx-0">A specific track</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between uni-p-16 p-lh uni-mb-8' onClick={()=>{changeSelect("SelectBeat")}}>

              <TextfieldSM className=" font-col-wh my-0 mx-0">A specific beat</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between uni-p-16 p-lh uni-mb-8' onClick={()=>{changeSelect("ReportRecentChatModal")}}>

              <TextfieldSM className=" font-col-wh my-0 mx-0">Recent messages this user sent you</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between uni-p-16 p-lh' onClick={()=>{changeSelect("UserReportReasonModal")}}>

              <TextfieldSM className=" font-col-wh my-0 mx-0">Something about this user</TextfieldSM>
              <DropworksRadioButton />
            </p>


          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">Cancel</DropworksSmallButton></CloseModalButton>
            <ButtonComponent id={select || "disabled"}>
              <DropworksSmallButton className="btn-wid m-0">Next</DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


