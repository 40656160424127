import { useNavigate } from "react-router-dom";
import { view } from "./view";

const MarketplaceFeatured =()=>{
    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo}))
}

export default MarketplaceFeatured;