import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import clipIcon from "../../../assets/steps/clip.svg";
import sendIcon from "../../../assets/steps/send button.svg";
import PdfImage from"../../../assets/img/pdf-image.png"
import DocImage from"../../../assets/img/doc-image.png"
import searchCrossIcon from "../../../assets/img/searchCrossIcon.svg";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  clearInput: () => void;
  sendMessage: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Updated type
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  inputValue: any;
  FunctionOnSecondSVG?: () => void;
}


export const view: FC<InputProps> = ({
  inputValue,
  handleInputChange,
  clearInput,
  sendMessage,
  handleKeyDown,
  className = "",
  placeholder = "Search",
  ...rest
}) => {

  const textareaRef = useRef<HTMLTextAreaElement>(null); // Reference for the textarea
  const [totalRows,setLineCount]=useState(1)
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Trigger reflow to recalculate scrollHeight accurately
      textarea.style.height = 'auto'; // Reset the height temporarily
      const scrollHeight = textarea.scrollHeight; // Get accurate scrollHeight after reset

      // Determine line height based on updated scrollHeight
      const lineHeight = scrollHeight < 59 ? 1 : scrollHeight < 79 ? 2 : scrollHeight < 101 ? 3 : 4;
      console.log(scrollHeight, "scrollHeight updated");

      setLineCount(lineHeight);
    }
  }, [inputValue]);
  const [file, setFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<string>('');
  
  const filePreviewStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '10px',
    marginTop: '10px',
  };

  const iconStyle: React.CSSProperties = {
    width: '40px',
    height: '40px',
    marginRight: '10px',
  };
  const renderPreview = () => {
    if (!file) return null;

    const fileURL = URL.createObjectURL(file);
    if (fileType.startsWith('image/')) {
      return <img src={fileURL} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />;
    } else if (fileType === 'application/pdf') {
      return (
        <div style={filePreviewStyle}>
          <img src={PdfImage} alt="PDF" style={iconStyle} />
          <div>
            <p>{file.name}</p>
            <p>PDF File</p>
          </div>
        </div>
      );
    } else if (fileType.startsWith('video/')) {
      return <video controls style={{ maxWidth: '100%', maxHeight: '200px' }}><source src={fileURL} type={fileType} /></video>;
    } else if (fileType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      return (
        <div style={filePreviewStyle}>
          <img src={DocImage} alt="Word" style={iconStyle} />
          <div>
            <p>{file.name}</p>
            <p>Word Document</p>
          </div>
        </div>
      );
    } else {
      return <p>Unsupported file type: {file.name}</p>;
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileType(uploadedFile.type);
    }
  };

  const handleIconClick = () => {
    document.getElementById('file-input')?.click();
  };

  return (
    <div className="message">
      <div className="uni-ml-72">{renderPreview()}</div> 
      <div className={`message-inp  ${className}`} 
       style={{height:totalRows === 1 ?'56px':totalRows ===2?"78px":totalRows===3?"100px":"130px"}} 
      >

<input
        id="file-input"
        type="file"
        style={{ display: 'none' }} // Hide the input
        onChange={handleFileChange}
        accept="image/*,application/pdf,video/*,.doc,.docx" // Accept specific file types
      />
 
        <img src={clipIcon} className="message-inp-icon mt-auto cursor-pointer uni-mb-16" alt="icon" onClick={handleIconClick} />

        <textarea
        ref={textareaRef}
        className="message-inp-input"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type your message here..."
        rows={1} 
   />
        <div className="message-inp-closeicon  mt-auto" >
          {/* {inputValue.length > 0 && ( */}
          <img
            src={sendIcon}
            className="message-inp-cross-icon  mt-auto   mx-auto uni-mb-16"
            onClick={sendMessage}
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
};
