import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';

import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { ButtonComponent, CloseModalButton } from '../../logo-header/view';

const view = () => {
  return (
    <div
      className="modal fade"
      id="UploadBanner2"
      aria-hidden="true"
      aria-labelledby="UploadBanner2"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog   mx-auto   modal-dialog-centered custom-modal-width ">
        <div className="modal-content modal-width-upload-banner">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Upload a Banner</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-16">Try to use a 16:9 high resolution banner</TextfieldLG>
            <TextfieldXSM className="ctm-txt2 uni-mb-56">(Don’t upload anything if you do not want this do be displayed on your profile)</TextfieldXSM>

            <ButtonComponent id="ProfilePictureModal2">
              <div className="uploadBanner_uploadbox mx-0 my-0">
                <img src={iconImg} className='uploadBanner-uploadicon' />
                <TextfieldSM className='text-content uploadBanner-upload-text mx-0 my-0'>Upload</TextfieldSM>
              </div>
            </ButtonComponent>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
              <DropworksSmallButton className="btn-wid m-0">Save</DropworksSmallButton></CloseModalButton>
          </div>
        </div>

      </div>
    </div>
  )
}

export default view;

