import { FC } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import {
  DropworksButton,
  DropworksDisableButton,
} from "../../../../common/custom-field/dropworks-button";
import { DropworksSelect } from "../../../../common/custom-field/dropworks-select";
import { TextfieldSM } from "../../../../common/custom-field/dropstock-text";
import { Heading2, Heading3 } from "../../../../common/custom-field/dropstock-heading";
import { SelectArrowDropdown } from "../../../../common/selection-dropdown";

const view: FC<viewType> = ({ navigateTo }) => {
  const options = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
    { value: "I prefer not to say", label: "I prefer not to say" },
  ];

  const CountryList = [
    { value: "Country1", label: "Country1" },
    { value: "Country2", label: "Country2" },
    { value: "Country3", label: "Country3" },
    { value: "Country4", label: "Country4" },
    { value: "Country5", label: "Country5" },
  ];
  
  

  return (
    <div className=" page-content row align-items-pages container px-0 m-auto h-100  min-h-800">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-6 col-xxl-5 px-0">
        <Heading3 className="signUp_heading my-0">
          Welcome to DropWorks
        </Heading3>
        <TextfieldSM className="uni-my-32 signUp-fill-normal">
          Please fill out all the fields
        </TextfieldSM>
        <div className="row ">
          <div className="col-6 pe-1 ps-0 ">
            <DropworksInput placeholder="Name" />
          </div>
          <div className="col-6 pe-0 ps-1">
            <DropworksInput placeholder="Surname" />
          </div>
        </div>
        <div className="row  ">
          <div className="col-6 pe-1 ps-0">
          <DropworksInput
              placeholder="Birth Date"
              type="text"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = e.target.value? "date":"text")}
              id="date"
            />
          </div>
          <div className="col-6 pe-0 ps-1 ">
            <DropworksDisableButton className="custom-select-dropdown track-overview-dropdown cursor-pointer bg-trans">
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Select"
                className="cursor-pointer"
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          </div>
        </div>
        <div className="row uni-mb-32">
          <DropworksDisableButton className="custom-select-dropdown track-overview-dropdown bg-trans">
            <SelectArrowDropdown
              options={CountryList}
              placeHolderValue="Country"
            ></SelectArrowDropdown>
          </DropworksDisableButton>
        </div>
        <DropworksButton onClick={navigateTo("/setting-up")}>
          Next
        </DropworksButton>
      </div>
    </div>
  );
};
export default view;
