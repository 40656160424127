
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { CloseModalButton } from "../../logo-header/view";

const view = () => {
  return (
    <div
      className="modal fade"
      id="ChangePassword"
      aria-hidden="true"
      aria-labelledby="ChangePassword"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title uni-mb-56">Change Password</h1>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <DropworksPassword
              className="custom-pass-space"
              placeholder="Current Password"
            />
            <DropworksPassword
              className="custom-confirm-pass-space"
              placeholder="New Password"
            />
            <DropworksPassword
              className="uni-mb-56"
              placeholder="Confirm New Password"
            />
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid btn-gray3 my-0 ml-0 uni-mr-16 btn-bg-gray3">
              Cancel
            </DropworksSmallButton>
            </CloseModalButton>{" "}
            <DropworksSmallButton className="btn-wid m-0 btn-bg-black2">
              Finish
            </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;


