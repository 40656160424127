import { FC, useState } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    tooltipTitle: string; 
}

export const DropworksInputIcon:FC<InputProps>=({tooltipTitle,...props})=>{
    const [show,setShow]=useState<boolean>(true);
    const showHide=()=>{
        setShow(!show)
    }
    return view({tooltipTitle, showHide,show,...props});
}