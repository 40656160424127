import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { FaExternalLinkAlt } from "react-icons/fa";
import fonticon from "../../../../assets/img/dropworkfonticon.png"
import { FaTiktok } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";
import languageIcon from "../../../../assets/img/language.png"


export const view: FC<any> = ({hide}) => {
 


    return (
        <div className="footer  px-0">
            <div className={hide?"d-none":"footer_first px-0 container container-xxl container-xl  px-0"}>
                <div className="row footer_first_columns px-0">
                    <div className="col-lg-4 col-12 col-md-4 fs-6 footer_first_columns_first order-1 order-sm-1  px-0">
                        <div className="footer_first_columns_first_heading">Company</div>
                        <div className="footer_first_columns_first_subheading">About</div>
                        <div className="footer_first_columns_first_subheading">Resource</div>
                    </div>
                    <div className="col-lg-4 col-12 col-md-4 fs-6 footer_first_columns_second  order-3 order-sm-2   px-0">
                        <div className="footer_first_columns_second_heading">Legal</div>
                        <div className="footer_first_columns_second_subheading">Terms of Use</div>
                        <div className="footer_first_columns_second_subheading">Privacy Policy</div>
                        <div className="footer_first_columns_second_subheading">Legal Notice</div>
                        <div className="footer_first_columns_second_subheading">GDPR</div>
                        <div className="footer_first_columns_second_subheading">Security Policy</div>
                        <div className="footer_first_columns_second_subheading">Cookie Settings</div>
                    </div>
                    <div className="col-lg-4 col-12 col-md-4 fs-6 footer_first_columns_third  order-2 order-sm-3 px-0">
                        <div className="footer_first_columns_third_heading">Support</div>
                        <div className="footer_first_columns_third_subheading">Help Center <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                        <div className="footer_first_columns_third_subheading">Status <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                    </div>
                </div>
            </div>
            <div className="footer_second">
                {/* <div className="d-flex footer_second_first container-new container"> */}
                <div className="d-flex footer_second_first w-100 ">
                    <div className="footer_second_first_texts">
                        <img src={fonticon} />
                        <span className="footer_second_first_texts_copyright fs-6"><span className="footer_second_first_texts_copyright_icon">&copy;</span> Copyright 2024 DropWorks Inc. All rights reserved</span>
                    </div>
                    <div className="footer_second_first_icons d-flex">
                        <div className="footer_second_first_icons_icon"><img src={languageIcon} /><span className="footer_second_first_icons_icon_english">English</span>
                        </div>
                        {/* <div className="footer_second_first_icons_english">English</div> */}
                        <div className="footer_second_first_icons_icon"><FaTiktok /></div>
                        <div className="footer_second_first_icons_icon"><FaTwitter /></div>
                        <div className="footer_second_first_icons_icon"><FaInstagram /></div>
                        <div className="footer_second_first_icons_icon"><FaYoutube /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
