import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";

const view = () => {
  return (
    <div
      className="modal fade"
      id="ChangePassword3"
      aria-hidden="true"
      aria-labelledby="ChangePassword3"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title uni-mb-56">Change Password</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <DropworksPassword
              className="uni-mb-32 ctm-inp-color1"
              placeholder="Current Password"
            />
            <DropworksPassword
              className="uni-mb-24"
              placeholder="New Password"
            />
            <DropworksPassword
              className="uni-mb-56 ctm-inp-color1"
              placeholder="Confirm New Password"
            />
          </div>
            <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
              <DropworksSmallButton className="btn-wid btn-gray3 my-0 ml-0 uni-mr-16 btn-bg-gray3">
                Back
              </DropworksSmallButton>{" "}
              <DropworksSmallButton className="btn-wid m-0">
                Finish
              </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;


