import React, { FC } from "react";
import "./styles.scss";
import { TextfieldLG, TextfieldXSM } from "../custom-field/dropstock-text";

export const view: FC<any> = ({
    navigateTo,
    image,
    arr
}) => {
    return (
        <div className="row">
            {arr.map((data:any, index:any) => (
                <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-2 user-icon" onClick={navigateTo("/user-profile")}>
                    <div className="user-icon-box"> </div>
                    <TextfieldLG className="user-icon-heading mt-0">{data.heading}</TextfieldLG>
                    <TextfieldXSM className="user-icon-subheading m-0">{data.subheading}</TextfieldXSM>
                </div>
            ))}
        </div>
    );
};
