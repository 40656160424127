import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import { CloseModalButton } from '../../logo-header/view';

const view = () => {
  return (
    <div
      className="modal fade"
      id="ChangeAlias"
      aria-hidden="true"
      aria-labelledby="ChangeAlias"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Change Alias</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className=" uni-mt-16 ctm-txt ctm-md-lh uni-mb-56">Please enter the desired release name for #ARTISNAME</TextfieldLG>
              <DropworksInput placeholder='#ARTISTNAME'></DropworksInput>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid m-0">Change</DropworksSmallButton></CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

