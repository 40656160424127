import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";

export const Footer=()=>{

    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }
const [hide,setHide]=useState(false);
    
useEffect(()=>{
    if(window.location.pathname === "/sign-up" ||
        window.location.pathname === "/login" ||
        window.location.pathname === "/fill-details" ||
        window.location.pathname === "/setting-up" ||
        window.location.pathname === "/recover-account" ||
        window.location.pathname === "/recover-account-email" ||
        window.location.pathname === "/beat-empty" ||
        window.location.pathname === "/track-page" ||
        window.location.pathname === '/select-portfolio'  ||
        window.location.pathname === '/composite-portfolio' ||
        window.location.pathname === "/dropworks-select" ||
        window.location.pathname === "/dropworks-composite"
    )
    {setHide(true)
    }
    else{setHide(false)}
        },[window.location.pathname])
    return(view({navigateTo,hide}))
}