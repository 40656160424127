import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { ButtonComponent } from '../../logo-header/view';
import { useEffect, useState } from 'react';

const view = () => {
  const [cooldown, setCooldown] = useState(false);
  const [timer, setTimer] = useState(0);

  const handleResendCode = () => {
    if (!cooldown) {
      setCooldown(true);
      setTimer(10); 
    }
  };

  useEffect(() => {
    if (cooldown && timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);

      if (timer === 1) {
        setCooldown(false);
      }

      return () => clearInterval(countdown);
    }
  }, [cooldown, timer]);
  return (
    <div
    className="modal fade"
    id="Modal4"
    aria-hidden="true"
    aria-labelledby="Modal4"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Secure your Account</h1>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Please confirm the code we’ve sent to your phone</TextfieldLG>
          <DropworksInput className="mb-0" placeholder="Code" />
          <TextfieldXSM
              className={`ctm-txt2 uni-mb-56 ${cooldown ? 'disabled' : ''}`}

            >
              <button onClick={handleResendCode} className='resend-btn '
                disabled={cooldown}>  {cooldown ? `Resend in ${timer}s` : 'Resend Code'}</button>
            </TextfieldXSM>
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        <ButtonComponent id="acc-secured">
          <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
          </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


export const openButton=()=>{
  console.log("ppppppppppppp")
  document.getElementById('okk')?.click();
}