import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { ButtonComponent } from '../../logo-header/view';
import { useEffect, useState } from 'react';
import "./styles.scss";

const view = () => {
  const [cooldown, setCooldown] = useState(false);
  const [timer, setTimer] = useState(0);

  const handleResendCode = () => {
    if (!cooldown) {
      setCooldown(true);
      setTimer(10); 
    }
  };

  useEffect(() => {
    if (cooldown && timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);

      if (timer === 1) {
        setCooldown(false);
      }

      return () => clearInterval(countdown);
    }
  }, [cooldown, timer]);
  return (
    <div
      className="modal fade"
      id="EmailModal1"
      aria-hidden="true"
      aria-labelledby="EmailModal1"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Confirm Your Identity</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Please confirm the code we’ve sent you</TextfieldLG>
            <DropworksInput className="mb-0" placeholder="Code" />
            <TextfieldXSM
              className={`ctm-txt2 uni-mb-56 ${cooldown ? 'disabled' : ''}`}

            >
              <button onClick={handleResendCode} className='resend-btn '
                disabled={cooldown}>  {cooldown ? `Resend in ${timer}s` : 'Resend Code'}</button>
            </TextfieldXSM>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <ButtonComponent id="EmailModal">
              <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton></ButtonComponent>
            <ButtonComponent id="EmailModal2">
              <DropworksSmallButton className="btn-wid m-0">Next</DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


