import { Carousel } from "react-responsive-carousel";
import "./styles.scss";
import FeaturedImg from "../../../assets/img/FeaturedGroupImg.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { DropworkGhostButton } from "../../../common/custom-field/dropworks-button";
import { UserLable } from "../../../common/user-labels";
import { BlueTik } from "../../../common/all-icons";
import { ProjectIcon } from "../../../common/project-icon";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import { BeatesIcon } from "../../../common/beats-icon";
import { viewType } from "./modal";
import { FC } from "react";

export const view: FC<viewType> = ({ navigateTo }) => {
  const labelArray = [
    "Investor",
    "Producer",
    "Vocalist",
    "Lyricist",
    "Beat Maker",
    "Curator",
    "Artwork Designer",
  ];
  const renderArrowPrev = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        className="control-arrow control-prev"
      >
        &#10094;
      </button>
    );

  const renderArrowNext = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        className="control-arrow control-next"
      >
        &#10095;
      </button>
    );

    const projectarr= [
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
      { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    ];
    const beatsarr=[
      { heading: "Beat Name", subheading: "Unlost" },
      { heading: "Beat Name", subheading: "Unlost" },
      { heading: "Beat Name", subheading: "Unlost" },
      { heading: "Beat Name", subheading: "Unlost" },
      { heading: "Beat Name", subheading: "Unlost" },
      { heading: "Beat Name", subheading: "Unlost" },
    ];
  return (
    <div className="featured container">
      <div className="featured_heading uni-mb-32">Popular this week</div>
      <div className="uni-mb-56">
        <Carousel
          className="featured-carousel"
          autoPlay={false}
          infiniteLoop={true}
          showThumbs={false}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
        >
          <div onClick={navigateTo("/user-profile")} >
            <img src={FeaturedImg} className="featured-carousel--img"  />
            <p className="legend p-0">
              <Heading3 className="featured-carousel-Heading">
                Michael FGD{" "}
                <span>
                  <BlueTik className="no-styles" />
                </span>
              </Heading3>
              <p className="featured-carousel-subHeading uni-mb-16">@fgdmike</p>
              <p className="d-flex">
                {labelArray.map((ele) => (
                  <UserLable className="uni-mr-16">{ele}</UserLable>
                ))}
              </p>
            </p>
          </div>
          <div  onClick={navigateTo("/user-profile")}>
            <img src={FeaturedImg} className="featured-carousel--img"  />
            <p className="legend p-0">
              <Heading3  className="featured-carousel-Heading">
                Michael FGD{" "}
                <span>
                  <BlueTik className="no-styles" />
                </span>
              </Heading3 >
              <p className="featured-carousel-subHeading uni-mb-16">@fgdmike</p>
              <p className="d-flex">
                {labelArray.map((ele) => (
                  <UserLable className="uni-mr-16">{ele}</UserLable>
                ))}
              </p>
            </p>
          </div>
          <div  onClick={navigateTo("/user-profile")}>
            <img src={FeaturedImg} className="featured-carousel--img" />
            <p className="legend p-0">
              <Heading3  className="featured-carousel-Heading">
                Michael FGD{" "}
                <span>
                  <BlueTik className="no-styles" />
                </span>
              </Heading3 >
              <p className="featured-carousel-subHeading uni-mb-16">@fgdmike</p>
              <p className="d-flex">
                {labelArray.map((ele) => (
                  <UserLable className="uni-mr-16">{ele}</UserLable>
                ))}
              </p>
            </p>
          </div>
          <div  onClick={navigateTo("/user-profile")}>
            <img src={FeaturedImg} className="featured-carousel--img"   />
            <p className="legend p-0">
              <Heading3  className="featured-carousel-Heading">
                Michael FGD{" "}
                <span>
                  <BlueTik className="no-styles" />
                </span>
              </Heading3 >
              <p className="featured-carousel-subHeading uni-mb-16">@fgdmike</p>
              <p className="d-flex">
                {labelArray.map((ele) => (
                  <UserLable className="uni-mr-16">{ele}</UserLable>
                ))}
              </p>
            </p>
          </div>
          <div  onClick={navigateTo("/user-profile")}>
            <img src={FeaturedImg} className="featured-carousel--img"/>
            <p className="legend p-0">
              <Heading3  className="featured-carousel-Heading">
                Michael FGD{" "}
                <span>
                  <BlueTik className="no-styles" />
                </span>
              </Heading3 >
              <p className="featured-carousel-subHeading uni-mb-16">@fgdmike</p>
              <p className="d-flex">
                {labelArray.map((ele) => (
                  <UserLable className="uni-mr-16">{ele}</UserLable>
                ))}
              </p>
            </p>
          </div>
          <div  onClick={navigateTo("/user-profile")}>
            <img src={FeaturedImg} className="featured-carousel--img"  />
            <p className="legend p-0">
              <Heading3  className="featured-carousel-Heading">
                Michael FGD{" "}
                <span>
                  <BlueTik className="no-styles" />
                </span>
              </Heading3 >
              <p className="featured-carousel-subHeading uni-mb-16">@fgdmike</p>
              <p className="d-flex">
                {labelArray.map((ele) => (
                  <UserLable className="uni-mr-16">{ele}</UserLable>
                ))}
              </p>
            </p>
          </div>
        </Carousel>
      </div>
      <div className="featured_heading uni-mb-32">Projects for you</div>
      <div className="library-usericon">
            <ProjectIcon arr={projectarr} />
          </div>
          <div className="featured_heading uni-mb-32">Hot Beats</div>
      <div className="library-usericon">
            <BeatesIcon arr={beatsarr} />
          </div>
    </div>
  );
};
