import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
    className="modal fade"
    id="EmailModal2"
    aria-hidden="true"
    aria-labelledby="EmailModal2"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">New Email Address</h1>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 uni-mb-8 ctm-txt">We will send you a verification code to confirm your new email </TextfieldLG>
          <TextfieldXSM className="ctm-txt2 uni-mb-56">Will require a login</TextfieldXSM>
          <DropworksInput className=" uni-mb-56" placeholder="Email" />
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        <ButtonComponent id="EmailModal1">
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton>
        </ButtonComponent>
        <ButtonComponent id="EmailModal6"> 
        <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
        </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

