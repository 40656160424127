import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksNormalButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { CloseModalButton } from "../../logo-header/view";
import uploadIcon from '../../../../../assets/steps/uploadicon.svg';
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";

const view = () => {

  const options = [
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 1', label: 'Type 1' },
  ]
  return (
    <div
      className="modal fade"
      id="UploadModal"
      aria-hidden="true"
      aria-labelledby="UploadModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered    mx-auto   custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-32 p-0">
            <h1 className="modal-title">Upload a File</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="text-content mt-0 uni-mb-16">
              File Type
            </TextfieldLG>
            {/* <DropworksSelect className="uni-mb-32">
              <option value="">Select</option>
            </DropworksSelect> */}
            <DropworksDisableButton className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Select"
              ></SelectArrowDropdown>
            </DropworksDisableButton>

            <TextfieldLG className="text-content uni-mb-16 mt-0">
              Short Description
            </TextfieldLG>
            <DropworksInput placeholder="Type" className="uni-mb-32" />

            <TextfieldLG className="text-content uni-mb-16 mt-0">
              File
            </TextfieldLG>
            <div className="inviteModal_uploadbox mx-0 my-0">
              <img src={uploadIcon} className="uploadicon" />
              <TextfieldSM className="text-content upload-text mx-0 my-0">
                Upload
              </TextfieldSM>
            </div>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
            <CloseModalButton>
            <DropworksDisableButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksDisableButton>
            </CloseModalButton>
            <DropworksNormalButton className="upload-modalbtn mb-0">
              Upload
           </DropworksNormalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
