import { FC, useState } from "react";
import "./styles.scss";
import { TextfieldLG, TextfieldSM } from "../../../common/custom-field/dropstock-text";
import { Heading4, Heading6 } from "../../../common/custom-field/dropstock-heading";
import { viewType } from "./modal";
import { BackArrowIcon } from "../../../common/all-icons";
import { setDropworksSelectoptin } from "../../../redux/reducer/dropworksSelect";
import { useDispatch } from "react-redux";
export const view: FC<viewType> = ({ navigateTo }) => {
  const dispatch=useDispatch()
  return (
    <div className="new-wallet container container-xxl min-height-cover">
      <div>
        <div className="overview">
         
          <div className="dropstocks-composite-portfolio">
            <div className="table-view">
                
              <div className="table-bg-parent-composite">
              <Heading4 className="dropstocks-composite-portfolio-head uni-mb-32"> 
                <span className="cursor-pointer" onClick={() => {
                    navigateTo("/dropworks-composite");
                  }}><BackArrowIcon className="uni-mr-16"/></span>DropWorks Composite portfolio  <TextfieldSM className="dropstocks-composite-portfolio-subhead uni-ml-32">DWC</TextfieldSM> 
          </Heading4>
          <div className="table-wrapper-select-portfolio">
                  <table className="table custom-table-select-portfolio">
                    <thead className="text-dark align-items-center justify-content-between">
                      <tr className="table-row-dropstock uni-mb-16">
                        <th scope="col" className="track-column">
                          Track
                        </th>
                        <th scope="col">Added</th>
                        <th scope="col" className="quantity-table-head">Quantity</th>
                        <th scope="col" className="anual-return">Est. Annual return</th>
                      </tr>
                    </thead>
                    <tbody>{[1,2,3,4,,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,].map((ele)=>{
                        return( <tr className="text-light d-flex justify-content-between align-items-center uni-mt-16">
                            <td className="date-time track-column">
                              <div className="d-flex gap-3 align-items-center">
                                <span className="select-box"></span>
                                <div className="d-flex">
                                  <Heading6 className="my-0 p-0 cursor-pointer" onClick={()=>navigateTo("/track-page")}>
                                    Track Name 1
                                  </Heading6>
                                </div>
                              </div>
                             </td>
                            <td>08/23/24</td>
                            <td className="last-table-data">800<span className="sub-quantity-price">/5000</span></td>
                            <td className="last-table-data">4.1%</td>
                          </tr>)
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
