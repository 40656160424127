import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import { CloseModalButton } from '../../logo-header/view';

const view = () => {
  return (
    <div
    className="modal fade"
    id="SocialLinksNotification"
    aria-hidden="true"
    aria-labelledby="SocialLinksNotification"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width ">
      <div className="modal-content modal-width-notification">
      <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Notifications</h1>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
          <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh">Select what you want to be notified about</TextfieldLG>
          <TextfieldXSM className='uni-mb-56 sn-ctm-txt'>You will still receive all notifications, but won’t be alerted on the ones turned off</TextfieldXSM>

          <p className='d-flex justify-content-between uni-p-16'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Announcements</TextfieldSM>
          <DropworksSwitch/>
          </p>
          <p className='d-flex justify-content-between uni-p-16'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Wallet</TextfieldSM>
          <DropworksSwitch/>
          </p>
          <p className='d-flex justify-content-between uni-p-16'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Invites</TextfieldSM>
          <DropworksSwitch/>
          </p>
          <p className='d-flex justify-content-between uni-p-16'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Marketplace</TextfieldSM>
          <DropworksSwitch/>
          </p>
          <p className='d-flex justify-content-between uni-p-16'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Favorites</TextfieldSM>
          <DropworksSwitch/>
          </p>
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <CloseModalButton>
          <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
          </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

