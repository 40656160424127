import { FC, useState, ChangeEvent } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/steps/uploadicon.svg";

import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import { DropworksButton, DropworksNormalButton } from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import { TextfieldLG, TextfieldSM, TextfieldXSM } from "../../../common/custom-field/dropstock-text";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const view: FC<viewType> = ({ navigateTo }) => {

  const [uploadedAudioFile, setUploadedAudioFile] = useState<File | null>(null);

  const upload = useSelector((state: RootState) => state.upload);


  const handleAudioUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedAudioFile(file);
    }
  };

  const handleRemoveAudio = () => {
    setUploadedAudioFile(null);
  };







  return (
    <div className="container">
      <Heading3 className="mx-0 my-0 heading-beat-empty">Add a Beat</Heading3>
      <TextfieldLG className="subHeading-beat-empty uni-mt-16 uni-mb-56">Please fill all mandatory fields to release the beat</TextfieldLG>
      <div className="row d-flex uni-mb-56 justify-content-between px-0 py-0">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0 beat-first-col">
          <TextfieldLG className="text-fields-beat uni-mb-16 mt-0">Beat Name</TextfieldLG>
          <DropworksInput placeholder="Name" className="uni-mb-32" />
          <TextfieldLG className="text-fields-beat uni-mb-16 mt-0">Description</TextfieldLG>
          <DropworksTextArea placeholder="Description (Optional)" className="uni-mb-32" rows={2} />

          <TextfieldLG className="text-fields-beat mt-0">Audio File</TextfieldLG>
          <TextfieldXSM className="text-fields-xsm-beat uni-mb-16 uni-mt-16">
            Allowed formats: <span className="font-yellow">.MP3, .WAV, .M4A</span>
          </TextfieldXSM>

          <div className="audio-upload-section">
            {uploadedAudioFile ? (
              <div className="audio-file-info d-flex align-items-center justify-content-between">
                <TextfieldSM className="file-name mx-0 my-0">
                  {(() => {
                    const fileName = uploadedAudioFile.name;
                    const extension = fileName.slice(fileName.lastIndexOf('.'));
                    const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'));

                    return nameWithoutExtension.length > 10
                      ? `${nameWithoutExtension.slice(0, 10)}...${extension}`
                      : fileName;
                  })()}
                </TextfieldSM>

                <span className="remove-file-icon ml-2" onClick={handleRemoveAudio}>X</span>
              </div>
            ) : (
              <label htmlFor="audio-upload" className="upload-label">
                <div className="beatEmpty_uploadbox flex-column">
                  <img src={iconImg} className="audio-img" />
                  <label className="uni-pt-16 uni-pb-16 beat-text">Beat Artwork</label>
                </div>
              </label>
            )}
            <input
              id="audio-upload"
              type="file"
              accept=".mp3, .wav, .m4a"
              onChange={handleAudioUpload}
              className="d-none"
            />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
          <TextfieldLG>Artwork</TextfieldLG>

          <div className="beatEmpty_uploadbox2 flex-column mb-3">

            {upload.Data ? <ButtonComponent id="beatartwork" className="w-100 h-100"> <img src={upload.Data} alt="Upload Icon" className="upload-img-2 w-100 h-100" />  </ButtonComponent> :
              <>
                <ButtonComponent id="beatartwork">
                  <img src={iconImg} alt="Upload Icon" className="upload-img-2" />
                </ButtonComponent>

                <label className="mt-4 beat-text">Beat Artwork</label>
              </>}


          </div>



        </div>
      </div>
      <div className="d-flex justify-content-end release-button">
        <DropworksNormalButton className={`btn-beat-empty ${uploadedAudioFile ? "beat-empty-button-done" : "beat-empty-button"}`}>Release</DropworksNormalButton>
      </div>
    </div>
  );
};

export default view;
