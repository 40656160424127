import { FC, useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import './styles.scss';


interface Crop {
  x: number;
  y: number;
}

interface ImageSize {
  width: number;
  height: number;
}

interface CroppedArea {
  width: number;
  height: number;
  x: number;
  y: number;
}
interface CropperToolProps {
  onImageCrop: (croppedImage: string) => void;
  onClose: () => void;
  iconImg: string;
  uploadText: string;
}

export const CropperTool: FC<CropperToolProps> = ({ onImageCrop, onClose, iconImg, uploadText }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedArea | null>(null);
  const [imageSize, setImageSize] = useState<ImageSize>({ width: 0, height: 0 });

  // Function to get cropped image
  const getCroppedImg = (imageSrc: string, pixelCrop: CroppedArea): Promise<string> => {
    const canvas = document.createElement('canvas');
    const image = new Image();

    return new Promise((resolve, reject) => {
      image.src = imageSrc;
      image.onload = () => {
        const ctx = canvas.getContext('2d');
        if (!ctx) return reject('Could not get context from canvas');

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(URL.createObjectURL(blob));
          } else {
            reject('Could not create blob from canvas');
          }
        }, 'image/jpeg');
      };
    });
  };

 
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

 
  // useEffect(() => {
  //   if (imageSrc) {
  //     const img = new Image();
  //     img.src = imageSrc;
  //     img.onload = () => {
  //       const { width, height } = img;
  //       setImageSize({ width, height });

  //       const cropperWidth = 300;
  //       const cropperHeight = 300;

  //       const zoomWidth = cropperWidth / width;
  //       const zoomHeight = cropperHeight / height;
  //       const newZoom = Math.max(zoomWidth, zoomHeight);

  //       setZoom(newZoom > 1 ? newZoom : 1);
  //     };
  //   }
  // }, [imageSrc]);


  const onCropComplete = useCallback((croppedArea: CroppedArea, croppedAreaPixels: CroppedArea) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);


  const handleCrop = async () => {
    if (imageSrc && croppedAreaPixels) {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onImageCrop(croppedImage);
      onClose();
    }
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 3));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 1));
  };

  return (
    <div className="cropper-container mt-5">
      {!imageSrc && (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            id="image-upload"
            className="d-none"
          />
          <label htmlFor="image-upload" className="upload-label d-flex flex-column align-items-center ">
            <img src={iconImg} className="uploadBanner-uploadicon cursor-pointer" alt="Upload Icon" />
            <span className="text-content uploadBanner-upload-text mx-0 my-0">{uploadText}</span>
          </label>
        </>
      )}
      {imageSrc && (<>
        <div className="cropper mt-5">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1} 
            cropSize={{ width: 300, height: 300 }} 
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            objectFit="cover"
          />
        </div>

        <div className="zoom-controls d-flex justify-content-between  position-absolute end-0 top-50 flex-column gap-2 uni-mr-8">
          <button onClick={handleZoomOut} className="btn btn-light">-</button>
          <button onClick={handleZoomIn} className="btn btn-light">+</button>
        </div>
        <div className="d-flex justify-content-between  position-absolute start-0 bottom-0  gap-2 uni-ml-8 uni-mb-8">
          <button onClick={handleCrop} className=' btn btn-light' >OK</button>
          <button onClick={()=>setImageSrc(null)} className=' btn btn-light'>Cancel</button>
        </div>
      </>
      )}

    </div>
  );
};
