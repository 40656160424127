import { Link, Outlet } from "react-router-dom";
import { FC, useRef ,ReactNode, useState} from "react";
import "./styles.scss";
import { viewType } from "./modal";
import logo from "../../../../assets/img/dropworklogo.png";
import { Footer } from "../footer";
import Modal4 from "../pop-ups/modal-4";
import DisplayNameModal from "../pop-ups/display-name";
import BiographyModal from "../pop-ups/biography-modal";
import SecurityQuestion from "../pop-ups/security-question";
import SecurityQuestion2 from "../pop-ups/security-question 2";
import ChangePasswordModal from "../pop-ups/change-password";
import ChangePasswordModal2 from "../pop-ups/change-password2";
import ChangePasswordModal3 from "../pop-ups/change-password3";
import DeleteAccount from "../pop-ups/delete-account";
import DeleteAccount3Modal from "../pop-ups/delete-account3";
import RegionModal from "../pop-ups/region-modal";
import iconimage from "../../../../assets/img/dropworklogo.png";
import DisplayLanguage from "../pop-ups/display-language";
import InviteAcceptRecieved, { ActiveInviteReceived } from "../pop-ups/active-invite-received";
import ReportModal1 from "../pop-ups/report-modal-1";
import ThankYouModal from "../pop-ups/thank-you-modal";
import InviteModal from "../pop-ups/invite-modal";
import InviteModal2 from "../pop-ups/invite-modal-2";
import AccountSecured from "../pop-ups/acc-secured";
import SecureYourAccount from "../pop-ups/secure-your-account";
import SocialLink8 from "../pop-ups/social-links-8";
import SocialLink14 from "../pop-ups/social-links-14";
import SocialLink9 from "../pop-ups/social-links-9";
import SocialLink13 from "../pop-ups/social-links-13";
import SocialLink10 from "../pop-ups/social-links-10";
import SocialLink12 from "../pop-ups/social-links-12";
import SocialLink11 from "../pop-ups/social-links-11";
import EmailModal1 from "../pop-ups/email-modal-1";
import EmailModal2 from "../pop-ups/email-modal-2";
import EmailModal6 from "../pop-ups/email-modal-6";
import MobilePhoneModal3 from "../pop-ups/mobile-phone-modal-3";
import MobilePhoneModalA from "../pop-ups/mobile-phone-modal-A";
import MobilePhoneModalB from "../pop-ups/mobile-phone-modal-B";
import ProfilePictureModal from "../pop-ups/profile-picture-modal";
import ProfilePictureModal2 from "../pop-ups/profile-picture-modal-2";
import ReportModal2 from "../pop-ups/report-modal-2";
import ReportModal3 from "../pop-ups/report-modal-3";
import ReportModal4 from "../pop-ups/report-modal-4";
import ReportRecentChatModal from "../pop-ups/report-recent-chat";
import SuccessfullyBlocked from "../pop-ups/successfully-blocked";
import TrackReportModal from "../pop-ups/track-report-reason";
import UserReportReasonModal from "../pop-ups/user-report-reason";
import BeatReportModal from "../pop-ups/beat-report-reason";
import ImpersonatingReportModal from "../pop-ups/impersonating-report-modal";
import AcceptedInviteReceived from "../pop-ups/accepted-invite-received";
import DeclinedInviteReceived from "../pop-ups/declined-invite-received";
import ExpiredInviteReceived from "../pop-ups/expired-invite-received";
import AcceptedInviteSent from "../pop-ups/accepted-invite-sent";
import ActiveInviteSent from "../pop-ups/active-invite-sent";
import DeclinedInviteSent from "../pop-ups/declined-invite-sent";
import ExpiredInviteSent from "../pop-ups/expired-invite-sent";
import RolesModal from "../pop-ups/roles-modal";
import DawsModal from "../pop-ups/daws-modal";
import Modal2 from "../pop-ups/modal-2";
import EmailModal from "../pop-ups/email-modal";
import BlockedUser1 from "../pop-ups/blockuser-1";
import BlockedUser2 from "../pop-ups/blockuser-2";
import BlockedModal from "../pop-ups/blocked-modal";
import Modal1 from "../pop-ups/modal-1";
import SelectTrack from "../pop-ups/select-track";
import SelectBeat from "../pop-ups/select-beat";
import SocialLinksNotification from "../pop-ups/social-links-notification";
import LoginHistorySocials from "../pop-ups/login-history";
import UploadBanner from "../pop-ups/upload-banner";
import UploadBanner2 from "../pop-ups/upload-banner-2";
import SocialLinksMain from "../pop-ups/socail-links-main";
import BeatArtWork from "../pop-ups/beat-artwork";
import ReleasedModal from "../pop-ups/release-modal";
import ApproveFile from "../pop-ups/approve-file";
import DeclineFile from "../pop-ups/decline-file";
import UploadModal from "../pop-ups/upload-modal";
import DepositModal from "../pop-ups/deposit-modal";
import WithdrawModal from "../pop-ups/withdraw-modal";
import InviteModal8 from "../pop-ups/invite-modal-8";
import SearchInvite from "../pop-ups/search-invite";
import ChangeAlias from "../pop-ups/change-alias";
import RemoveCollaborator from "../pop-ups/remove-collaborator";
import DeleteFile from "../pop-ups/delete-file";
import ApproveFile2 from "../pop-ups/approve-file-2";
import DeleteChat from "../pop-ups/delete-chat";
import Spline from "@splinetool/react-spline";
import DropWorksSelectSubmit from "../pop-ups/dropworks-select-submit";
import SubMissionThankyou from "../pop-ups/submission-thankyou";
import SellDropStocks from "../pop-ups/sell-dropstocks";
import SellDropStocks3 from "../pop-ups/sell-dropstocks-3";
import DropStocksSelectsubmit3 from "../pop-ups/dropworks-select-submit-3";
import DropWorksCompositSubmit from "../pop-ups/dropworks-composit-submit";
import { DropworksSecondaryButton, DropworksSmallButton } from "../../../../common/custom-field/dropworks-button";
import { IoMenu, IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";

interface PopUpId{
popupId?:string
}

const view: FC<viewType> = ({ dropAnimationRef,navigateTo }) => {

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <>
      <Modal4/>
      <ApproveFile2/>
      <DeleteFile/>
      <DeclineFile/>
      <ApproveFile/>
      <SocialLinksMain/>
      <UploadBanner2/>
      <UploadBanner/>
      <LoginHistorySocials/>
      <SocialLinksNotification/>
      <AccountSecured/> 
      <SecureYourAccount/>
      <SocialLink8/>
      <SocialLink14/>
      <SocialLink9/>
      <SocialLink13/> 
      <SocialLink10/> 
      <SocialLink12/>
      <SocialLink11/>
      <EmailModal1/> 
      <EmailModal2/>
      <EmailModal6/>
      <MobilePhoneModal3/>
      <MobilePhoneModalA/> 
      <MobilePhoneModalB/> 
      <ProfilePictureModal/>
      <ProfilePictureModal2/>
      <DisplayNameModal/> 
      <BiographyModal/>
      <SecurityQuestion/>
      <SecurityQuestion2/>
      <ChangePasswordModal/>
      <ChangePasswordModal2/>
      <ChangePasswordModal3/>
      <DeleteAccount/>
      <DeleteAccount3Modal/>
      <RegionModal/>
       <DisplayLanguage/>
       <ActiveInviteReceived/>
       <AcceptedInviteReceived/>
       <DeclinedInviteReceived/>
       <ExpiredInviteReceived/>
       <AcceptedInviteSent/>
       <ActiveInviteSent/>
       <DeclinedInviteSent/>
       <ExpiredInviteSent/>
       <ReportModal1/> 
       <ReportModal2/>
       <ReportModal3/> 
       <ReportModal4/> 
       <ReportRecentChatModal/>
       <SuccessfullyBlocked/>
       <ThankYouModal/> 
       <TrackReportModal/>
       <UserReportReasonModal/>
       <BeatReportModal/>
       <ImpersonatingReportModal/>
       <RolesModal/>
       <DawsModal/>
       <InviteModal/>
       <InviteModal2/>
       <Modal2/>
       <EmailModal/>
       <BlockedUser1/>
       <BlockedUser2/>
       <BlockedModal/>
       <Modal1/>
       <SelectTrack/>
       <SelectBeat/>
       <BeatArtWork/>
       <ReleasedModal/>
       <UploadModal/>
       <DepositModal/>
       <WithdrawModal/>
       <InviteModal8/>
       <SearchInvite/>
       <ChangeAlias/>
       <RemoveCollaborator/>
       <DeleteChat/>
       <DropWorksSelectSubmit/>
       <SubMissionThankyou/>
       <SellDropStocks/>
       <SellDropStocks3/>
       <DropStocksSelectsubmit3/>
       <DropWorksCompositSubmit/>
       <ReleasedModal/>
      
        
      <div className="task container-fluid px-0">

<div className="newstudioheader_content logo-header-content">
        <div className="newstudioheader_content_nav">      
          <ul className="newstudioheader_content_nav_sidebar"> 
            <div className="newstudioheader_content_nav_sidebar_logo">
              <li>
                <Link to="/home">
                  <img src={iconimage} />
                </Link>
              </li>
              <li >
                <Link to="#">X</Link>
              </li>
            </div>
          
            
            <li>
              <Link to="/marketplace-products">Products</Link>
            </li>
            <li>
              <Link to="#">Trade</Link>
            </li>
            <li>
              <Link to="#">Portfolio</Link>
            </li>
            <li className=" newstudioheader_content_nav_sidebar_links">
              <Link to="#">
                Studio <MdKeyboardArrowDown />
              </Link>
              {isDropdownOpen ? (
                <ul>
                  <li>
                    <a href="#">Featured</a>
                  </li>
                  <li>
                    <a href="#">DropStocks</a>
                  </li>
                  <li>
                    <a href="#">DropOffers</a>
                  </li>
                  <li>
                    <a href="#">Collection</a>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
          
          </ul>
          <div className="newstudioheader_content_nav_navbar">
            <div className="newstudioheader_content_nav_navbar_logo ">
              <li>
                <Link to="/home">
                  <img src={iconimage} />
                </Link>
              </li>
            </div>
            {window.location.pathname==='/home' &&
            <>
            <ul className="newstudioheader_content_nav_navbar_first ">
            <li className="hideOnMobile newmarketplace_content_nav_navbar_first_links dropdown"><Link to="/marketplace-products">Marketplace <MdKeyboardArrowDown className="uni-ml-8" /></Link>
                                <ul className="newmarketplace_content_nav_navbar_first_links_dropdown dropdown_menu uni-mt-16 p-0">
                                    <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu newmarketplace_content_nav_navbar_first_links-2 dropdown p-0 mini-submenus"><Link to="/marketplace-products"><div  className="uni-p-16" >Product  <MdKeyboardArrowDown className="uni-ml-8" /></div></Link>

                                        <ul className="newmarketplace_content_nav_navbar_first_links_dropdown2 dropdown_menu2 sub-dropdown-ul  pt-0">
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/marketplace-dropworks-composite">DropWorks Composite</Link></li>
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/marketplace-dropworks-select">DropWorks Select </Link></li>
                                        </ul>


                                    </li>
                                    <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu newmarketplace_content_nav_navbar_first_links-2 dropdown p-0 mini-submenus"><Link to="#"><div  className="uni-p-16" >Trade  <MdKeyboardArrowDown className="uni-ml-8" /></div></Link>

                                        <ul className="newmarketplace_content_nav_navbar_first_links_dropdown2 dropdown_menu2 sub-dropdown-ul pt-0">
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/dropworks-composite">DropWorks Composite</Link></li>
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/dropworks-select">DropWorks Select </Link></li>
                                        </ul>
 

                                    </li>
                                    <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu p-0 mini-submenus"><Link to="/personal-portfolio"><div  className="uni-p-16" >Portfolio </div> </Link></li>
                                </ul>
                            </li>
              <li className="hideOnMobile newstudioheader_content_nav_navbar_first_links dropdown">
                <Link to="/featured">
                  Studio <MdKeyboardArrowDown className="uni-ml-8" />
                </Link>
                <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu p-0 w-185">
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                    <Link className=" dropdowns-li" to="/featured">Featured</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                    <Link to="/library" className=" dropdowns-li">Library</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                  <Link to="/invite " className=" dropdowns-li">Invites</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                    <Link to="/create" className=" dropdowns-li">Create</Link>
                  </li>
                </ul>
              </li>
            
              <li className="hideOnMobile">
                <Link to="/personal-portfolio">Bookings </Link>
              </li>
           
              <li className="hideOnMobile" onClick={navigateTo("/search")}>
                <IoSearchSharp className="cursor-pointer" />
              </li>
            </ul>

            <ul className="newstudioheader_content_nav_navbar_second">
              <li className="mar-mr-12">
                <Link to="/notification">
                  {/* <img src={BellIcon} /> */}
                </Link>
              </li>
              <li className="me-0">
                <Link to="/message-chat">
                  {/* <img src={NotificationIcon} /> */}
                </Link>
              </li>
              <div className="task_header_logoheaderbutton">
         <DropworksSmallButton className="mb-0 task_header_logoheaderbutton_login" onClick={navigateTo("/login")} >Login</DropworksSmallButton>
         <DropworksSecondaryButton className="mb-0 task_header_logoheaderbutton_signup" onClick={navigateTo("/sign-up")}>Signup</DropworksSecondaryButton>
         </div>
              <li className="imgg  newstudioheader_content_nav_navbar_second_links">
                <Link to="#">
                  {/* <img src={UserIcon} /> */}
                </Link>
              </li>
              <li className="menu-button">
                <Link to="#">
                  <IoMenu />
                </Link>
              </li>
            </ul>
            </>
}
          </div>
        </div>
         </div>
        <div className="task_outlet d-flex ">
            <Outlet />
            <Footer />
        </div>
   
      </div>
            
    </>
  );
};

export default view;


interface ButtonComponentProps {
  id: string;
  name?: string;
  children?: ReactNode; 
  className?: string; 
}
interface ButtonComponentPropsClose {
  name?: string;
  children?: ReactNode; 
  className?: string; 
}

export const ButtonComponent: FC<ButtonComponentProps> = ({ id, name, children, className }) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);

  return (
    <a
      ref={buttonRef}
      className={`open-modal m-0  ${className}`} 
      data-bs-toggle="modal"
      href={`#${id}`}
      role="button"
    >
      {children || name}
    </a>
  );
};



export const CloseModalButton: FC<ButtonComponentPropsClose> = ({ name, children, className }) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);
  return (
    <a
    ref={buttonRef}
    className={`open-modal m-0 ${className}`} 
    data-bs-dismiss="modal"
    role="button"
  >
    {children || name}
  </a>
  );
};