import React, { FC, useState, useEffect, useRef } from "react";
import "./styles.scss";

interface TooltipProps {
  title: string;
  position?: "top" | "right" | "bottom" | "left"; // Position of the tooltip
  children: React.ReactNode;
  className?: string;
}

const CustomTooltip: FC<TooltipProps> = ({ title, position = "top", children, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false); 
  const tooltipRef = useRef<HTMLDivElement>(null); 

  
  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => !isClicked && setIsVisible(false); 
  const showOnClick = () => {
    setIsVisible(true);  
    setIsClicked(true);  
  };


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsVisible(false);  
        setIsClicked(false);
      }
    };

  
    document.addEventListener("mousedown", handleClickOutside);


    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    <div
      className="tooltip-wrapper cursor-pointer"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}  
      onClick={showOnClick}      
      ref={tooltipRef}            
    >
      <div className={`tooltip-box tooltip-${position} ${isVisible ? "visible" : ""}`}>
        {title}
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

export default CustomTooltip;
