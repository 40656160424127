import { FC, useState } from "react";
import "./styles.scss";
import {
  TextfieldLG,
  TextfieldSM,
} from "../../../common/custom-field/dropstock-text";

import { viewType } from "./modal";
import {
  Heading4,
  Heading6,
} from "../../../common/custom-field/dropstock-heading";
import { DropworksActiveButton } from "../../../common/custom-field/dropworks-button";
import { ButtonComponent } from "../../shared/components/logo-header/view";

export const view: FC<viewType> = ({ navigateTo }) => {
  return (
    <div className="new-wallet container container-xxl min-height-cover mt-0">
      <div>
        <div className="overview">
          <div className="dropstocks-personal-portfolio">
            <div className="table-view">
              <div className="table-bg-parent-personal">
                <Heading4 className="dropstocks-personal-portfolio-head uni-mb-40">
                  Personal Portfolio
                </Heading4>
                <div className="table-wrapper-personal-portfolio">
                  <table className="table custom-table-personal-portfolio">
                    <thead className="text-dark align-items-center justify-content-between">
                      <tr className="table-row-dropstock uni-mb-16">
                        <th scope="col" className="track-column">
                          Track
                        </th>
                        <th scope="col">Owned</th>
                        <th scope="col text-center">DWC</th>
                        <th scope="col text-end">DWS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-light d-flex justify-content-between align-items-center uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 cursor-pointer uni-fw-600" onClick={navigateTo("/track-page")}>
                                Track Name 1
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td className="personal-portfolio-submit"> <ButtonComponent id="SelectSubmit">Submit</ButtonComponent></td>
                        <td>-</td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3 ">
                               <span className="cursor-pointer  uni-fw-600" onClick={navigateTo("/track-page")}>Track Name 1</span> 
                                <DropworksActiveButton className="personal-portfolio-dwc">
                                  DWC
                                </DropworksActiveButton>
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        
                        <td className="personal-portfolio-sell"><ButtonComponent id="SellDropStocks" className="personal-portfolio-sell">Sell</ButtonComponent></td>
                        <td >-</td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3">
                              <span className="cursor-pointer  uni-fw-600" onClick={navigateTo("/track-page")}>Track Name 1</span> 
                                <DropworksActiveButton className="personal-portfolio-dws ">
                                  DWS
                                </DropworksActiveButton>
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td>-</td>
                        <td className="personal-portfolio-sell"><ButtonComponent id="SellDropStocks" className="personal-portfolio-sell">Sell</ButtonComponent></td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3">
                              <span className="cursor-pointer uni-fw-600 " onClick={navigateTo("/track-page")}>Track Name 1</span> 
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td >-</td>
                     
                        <td className="personal-portfolio-submit">   <ButtonComponent id="SelectSubmit">Submit</ButtonComponent></td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box "></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3">
                              <span className="cursor-pointer  uni-fw-600" onClick={navigateTo("/track-page")}>Track Name 1</span> 
                                <DropworksActiveButton className="personal-portfolio-dwc">
                                  DWC
                                </DropworksActiveButton>
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td className="personal-portfolio-sell"><ButtonComponent id="SellDropStocks" className="personal-portfolio-sell">Sell</ButtonComponent></td>
                        <td>-</td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 cursor-pointer uni-fw-600" onClick={navigateTo("/track-page")}>
                                Track Name 1
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td className="personal-portfolio-submit"> <ButtonComponent id="SelectSubmit">Submit</ButtonComponent></td>
                        <td>-</td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3 ">
                               <span className="cursor-pointer  uni-fw-600" onClick={navigateTo("/track-page")}>Track Name 1</span> 
                                <DropworksActiveButton className="personal-portfolio-dwc">
                                  DWC
                                </DropworksActiveButton>
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        
                        <td className="personal-portfolio-sell"><ButtonComponent id="SellDropStocks" className="personal-portfolio-sell">Sell</ButtonComponent></td>
                        <td >-</td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3">
                              <span className="cursor-pointer  uni-fw-600" onClick={navigateTo("/track-page")}>Track Name 1</span> 
                                <DropworksActiveButton className="personal-portfolio-dws ">
                                  DWS
                                </DropworksActiveButton>
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td>-</td>
                        <td className="personal-portfolio-sell"><ButtonComponent id="SellDropStocks" className="personal-portfolio-sell">Sell</ButtonComponent></td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3">
                              <span className="cursor-pointer uni-fw-600 " onClick={navigateTo("/track-page")}>Track Name 1</span> 
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td >-</td>
                     
                        <td className="personal-portfolio-submit">   <ButtonComponent id="SelectSubmit">Submit</ButtonComponent></td>
                      </tr>
                      <tr className="text-light d-flex justify-content-between align-items-center  uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box "></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 d-flex align-items-center gap-3">
                              <span className="cursor-pointer  uni-fw-600" onClick={navigateTo("/track-page")}>Track Name 1</span> 
                                <DropworksActiveButton className="personal-portfolio-dwc">
                                  DWC
                                </DropworksActiveButton>
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>800</td>
                        <td className="personal-portfolio-sell"><ButtonComponent id="SellDropStocks" className="personal-portfolio-sell">Sell</ButtonComponent></td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
