import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { useEffect, useState } from 'react';
import { CropperTool } from '../../../../../common/cropper-tool/view';
import { useDispatch } from 'react-redux';
import { setUploadData } from '../../../../../redux/reducer/uploadSlice';

const view = () => {
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropperOpen, setCropperOpen] = useState(false);



  const handleImageCrop = (croppedImage: any) => {
    setCroppedImage(croppedImage);
  };
  const dispatch = useDispatch()
  return (
    <div
      className="modal fade"
      id="beatartwork"
      aria-hidden="true"
      aria-labelledby="beatartwork"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width m-auto ">
        <div className="modal-content modal-width-upload-banner">
          <div className='modal-cross-icon' onClick={()=>{setCroppedImage(null); setCropperOpen(false)}}><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16' /></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Upload Beat Artwork</h1>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-56">Use a square high resolution picture</TextfieldLG>
            <div className="uploadBanner_uploadbox mx-0 my-0 position-relative">
              {croppedImage == null ? (
                <CropperTool
                  onClose={() => setCropperOpen(false)}
                  onImageCrop={handleImageCrop}
                  iconImg={iconImg}
                  uploadText="Upload"
                />
              ) : (
                <img src={croppedImage} className="uploadBanner-uploadicon cursor-pointer w-100 h-100" />
              )}
            </div>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
              <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16" onClick={()=>setCroppedImage(null)}>Cancel</DropworksSmallButton>
            </CloseModalButton>

            {croppedImage == null ? <DropworksSmallButton className="btn-wid m-0 disabled-btn" disabled>Save</DropworksSmallButton> : <>
              <CloseModalButton>
                <DropworksSmallButton className="btn-wid m-0" onClick={() => {dispatch(setUploadData(croppedImage)),setCroppedImage(null)}}>Save</DropworksSmallButton>
              </CloseModalButton>
            </>}



          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

