import { TextfieldLG, TextfieldSM } from '../../../../../common/custom-field/dropstock-text';
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button';
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import './styles.scss';
import { CloseModalButton } from '../../logo-header/view';
import userIcon from '../../../../../assets/steps/UserIcon.svg';
import { useState } from 'react';

const view = () => {
  
  const [blockedUsers, setBlockedUsers] = useState([
    { id: 1, name: '#USERNAME', blocked: true },
    { id: 2, name: '#USERNAME', blocked: true },
    { id: 3, name: '#USERNAME', blocked: true },
  ]);

  const handleToggleBlock = (id:number) => {
    setBlockedUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, blocked: !user.blocked } : user
      )
    );
  };

  return (
    <div
      className="modal fade"
      id="BlockedUser1"
      aria-hidden="true"
      aria-labelledby="BlockedUser1"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Blocked Users</h1>
          </div>

          <div className="modal-body uni-mb-56 pb-0 ps-0 pt-0 uni-pr-72">
            <TextfieldLG className="uni-mt-16 uni-mb-56 ctm-txt">Manage blocked users</TextfieldLG>

            {blockedUsers?.map((user) => (
              <div key={user.id} className='d-flex uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-3'>
                
                  {user.blocked ?(
                    <>
                      <img src={userIcon} alt={user.name} />
                    <TextfieldSM className='block-username '>{user.name}</TextfieldSM>
                    </>
                  ) :(
                    <>
                    <img src={userIcon} alt={user.name} className='block-img' />
                    <TextfieldSM className='block-username-block'>{user.name}</TextfieldSM>
                    </>
                  )}
                </div>
       
                {user.blocked ? (
                  <DropworksDisableButton className='unblock-button' onClick={() => handleToggleBlock(user.id)}>
                    Unblock
                  </DropworksDisableButton>
                ) : (
                  <DropworksDisableButton className='undo-button' onClick={() => handleToggleBlock(user.id)}>
                    Undo
                  </DropworksDisableButton>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
              <DropworksSmallButton className="btn-wid m-0">Save</DropworksSmallButton>
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default view;
