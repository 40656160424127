import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import './styles.scss';
import { ButtonComponent } from '../../logo-header/view';


const view = () => {
  return (
    <div
    className="modal fade"
    id="SocialLink10"
    aria-hidden="true"
    aria-labelledby="SocialLink10"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Social Links</h1>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Please input your Instagram public link</TextfieldLG>
          <DropworksInput className="mb-0 dropinput chg-plchold-col" placeholder="https://www.instagram.com/" />

        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        <ButtonComponent id="SocialLinksMain">
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton>
        </ButtonComponent>
        <ButtonComponent id="SocialLinksMain">
         <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
         </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


