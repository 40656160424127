import { ChangeEvent, FC, useState } from "react";
import "./styles.scss";
import Tabs from "../../../common/tabs";
import { DropworksSearchInput } from "../../../common/custom-field/dropworks-search-input";
import OffNotification from "../../../assets/steps/muted icon.svg";
import { HiDotsHorizontal } from "react-icons/hi";
import { DropworksMessageInput } from "../../../common/custom-field/dropworks-input-messagebox";
import { ArrowDropdown, MeatballsMenuDropdown } from "../../../common/icons";
import TabsComponent from "../../../common/tabs-component/view";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";
import { Link } from "react-router-dom";
import PdfImage from"../../../assets/img/pdf-image.png"
import DocImage from"../../../assets/img/doc-image.png"

export const view: FC<any> = () => {
  const [chatHistory, setChatHistory] = useState([
    {
      actionType: 1,
      message: "We have Recieved Message",
      time: "15:46",
    },
    { actionType: 0, message: "We have Sent Message", time: "15:46" },
  ]);
  const [show, setShow] = useState(false);

  const sendMessage = (message: string) => {
    const newMessage = {
      actionType: 0,
      message: message,
      time: new Date().toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    setChatHistory((prevHistory) => [...prevHistory, newMessage]);
  };

  const click = () => {
    setShow(!show);
  };

  const [dropdown, setdropDown] = useState(false);
  const dropclick = () => {
    setdropDown(!dropdown);
  };

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
    setActiveIndex1(null)
    setActiveIndex2(null)
    setActiveIndex3(null)
  };

  const allChat = [
    [
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: true,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl:
          "https://i0.wp.com/picjumbo.com/wp-content/uploads/pure-nature-landscape-single-tree-in-green-field-free-photo.jpg?w=600&quality=80",
        name: "Project Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Project",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
    ],
    [
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: true,
      },
    ],
    [
      {
        imgUrl:
          "https://i0.wp.com/picjumbo.com/wp-content/uploads/pure-nature-landscape-single-tree-in-green-field-free-photo.jpg?w=600&quality=80",
        name: "Project Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Project",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
    ],
  ];

 
  const [activeIndex1, setActiveIndex1] = useState<number | null>(null);
  const [activeIndex2, setActiveIndex2] = useState<number | null>(null);
  const [activeIndex3, setActiveIndex3] = useState<number | null>(null);


  
  const ErrorImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxqCQMRexiN9Iu6yVJQt3py5bPb13DRIqCXA&s";

  const [chattingTab, setChattingTab] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<string>('');

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileType(uploadedFile.type);
    }
  };

  const handleIconClick = () => {
    document.getElementById('file-input')?.click();
  };

  const renderPreview = () => {
    if (!file) return null;

    const fileURL = URL.createObjectURL(file);
    if (fileType.startsWith('image/')) {
      return <img src={fileURL} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />;
    } else if (fileType === 'application/pdf') {
      return (
        <div style={filePreviewStyle}>
          <img src={PdfImage} alt="PDF" style={iconStyle} />
          <div>
            <p>{file.name}</p>
            <p>PDF File</p>
          </div>
        </div>
      );
    } else if (fileType.startsWith('video/')) {
      return <video controls style={{ maxWidth: '100%', maxHeight: '200px' }}><source src={fileURL} type={fileType} /></video>;
    } else if (fileType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      return (
        <div style={filePreviewStyle}>
          <img src={DocImage} alt="Word" style={iconStyle} />
          <div>
            <p>{file.name}</p>
            <p>Word Document</p>
          </div>
        </div>
      );
    } else {
      return <p>Unsupported file type: {file.name}</p>;
    }
  };

  const filePreviewStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '10px',
    marginTop: '10px',
  };

  const iconStyle: React.CSSProperties = {
    width: '40px',
    height: '40px',
    marginRight: '10px',
  };

  return (
    <div className="newmessage pb-3 px-0">
  <div>
     </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 w-100 ">
        <div className="d-flex">
          <div className="newmessage-left">
            <Heading2 className="newmessage-left-heading uni-mb-24 uni-ml-16">Messages</Heading2>
            <Tabs
              id="10"
              tabsTitle={["All", "Unread", "Projects"]}
              className="uni-mb-24 uni-ml-16 message-tabs"
              checkTabState={checkTabState}
            />
            <DropworksSearchInput className="uni-mb-24 uni-ml-16" />
            <div className="newmessage-left-chatsection">
              <TabsComponent tabsValue={tabsValue} components={
                [<>{allChat[0].map((ele,index) => {
                  return (
                    <div
                    className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
                      activeIndex1 === index ? "active-chat" : ""
                    }`}
                    
                      onClick={() => {setChattingTab(ele.type), setActiveIndex1(index)}}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16">
                        <p className="newmessage-left-chatsection-chat-heading ">
                          {ele.name}{" "}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && ele.name === "Project Name" && (
                              <ArrowDropdown pagename="message"
                                className="px-0"
                                arr={["mute"]}
                              />
                            )}
                            {ele.seen && !ele.allowNotification && ele.name != "Project Name" && (
                              <ArrowDropdown pagename="message"
                                className="px-0"
                                arr={["Unmute", "Block", "Report", "Delete"]}
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>,
                <>{allChat[1].map((ele,index) => {
                  return (
                    <div

                      className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
                        activeIndex2 === index ? "active-chat" : ""
                      }`}
                      
                        onClick={() => {setChattingTab(ele.type), setActiveIndex2(index)}}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16">
                        <p className="newmessage-left-chatsection-chat-heading ">
                          {ele.name}{" "}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && (
                              <ArrowDropdown pagename="message"
                                className="px-0"
                                arr={["Unmute", "Block", "Report", "Delete"]}
                                id={`${index}`}
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>
                  ,
                <>{allChat[2].map((ele,index) => {
                  return (
                    <div
                   
                      className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
                        activeIndex3 === index ? "active-chat" : ""
                      }`}
                      
                        onClick={() => {setChattingTab(ele.type), setActiveIndex3(index)}}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16">
                        <p className="newmessage-left-chatsection-chat-heading ">
                          {ele.name}{" "}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && (
                              <ArrowDropdown pagename="message"
                                className="px-0"
                                arr={["mute"]}
                              
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>
                ]
              } />

            </div>
          </div>
          {chattingTab && (
            <div className="newmessage-right uni-px-24">
              <div className="newmessage-right-heading-content d-flex align-items-center">
                {" "}
                <img
                  src={
                    chattingTab === "Project"
                      ? "https://i0.wp.com/picjumbo.com/wp-content/uploads/pure-nature-landscape-single-tree-in-green-field-free-photo.jpg?w=600&quality=80"
                      : ""
                  }
                  className={
                    chattingTab === "Artist"
                      ? "img-profilepic"
                      : "img-profilepic-project"
                  }
                  onError={(e) => (e.currentTarget.src = ErrorImg)}
                  alt="image"
                />
                <p  className="newmessage-right-heading-content-heading uni-mr-16"><Link to={ chattingTab === "Artist" ? "/user-profile":"#"} className="uni-pl-16">
                  {chattingTab} Name
                </Link></p>
                { chattingTab === "Project"?  
                 <MeatballsMenuDropdown pagename="message"
                  height="6px"
                  arr={["mute"]}
                ></MeatballsMenuDropdown>
                :   <MeatballsMenuDropdown pagename="message"
                  height="6px"
                  arr={["Unmute", "Block", "Report", "Delete"]}
                ></MeatballsMenuDropdown>}
             
              </div>
              <div className="newmessage-right-chatting justify-content-end uni-py-32">
                <div className="newmessage-chat-history flex-column">
                  {chatHistory.map((items) => (
                    <>
                      {items.actionType === 1 ? (<>

                        <div className="newmessage-receiver">
                          {chattingTab === "Project" && (
                            <img
                              src={
                                chattingTab === "Project" ? "" : "ele.imgUrl"
                              }
                              className={
                                chattingTab === "Project"
                                  ? "img-profilepic uni-mr-8 "
                                  : "img-profilepic-project"
                              }
                              onError={(e) => (e.currentTarget.src = ErrorImg)}
                              alt="image"
                            />
                          )}
                          <p className="newmessage-receiver-chat-triangle"></p>
                          <div className="newmessage-receiver-message">
                            <span className="newmessage-receiver-message-received-message">{items.message}</span>
                            <span className="fs-gray3 uni-fw-400 newmessage-receiver-message-time">
                              {items.time}
                            </span>
                          </div>
                        </div>
                      </>
                      ) : (
                        ""
                      )}
                      {items.actionType === 0 ? (
                        <>
                          <div className="newmessage-sender justify-content-end">
                            <div className="newmessage-sender-message ">
                              <span className="newmessage-sender-message-sender-message">{items.message}</span>
                              <span className="uni-fw-400 newmessage-sender-message-time">{items.time}</span>
                            </div>
                            <p className="newmessage-sender-chat-triangle"></p>
                          </div>

                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
                <div className="newmessage-send-message">
                  <DropworksMessageInput
                    placeholder="Type Search"
                    message={sendMessage}
                                    />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};