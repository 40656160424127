import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import { ButtonComponent } from '../../logo-header/view';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';

const view = () => {
  return (
    <div
    className="modal fade"
    id="Modal2"
    aria-hidden="true"
    aria-labelledby="Modal2"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width-s">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header  ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Starting out</h1>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt">Pick your favorite genres </TextfieldLG>
          <TextfieldXSM className='ctm-daws-text uni-mb-56'>(Select at least three)</TextfieldXSM>
          <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>

                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox/></p>
               </label>

               
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <div className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under-modal2'>Genre</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
          </div>
          

        </div>  
        
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
        <ButtonComponent id="SecureYourAccount">
        <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
        </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

