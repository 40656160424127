import React, { useState } from "react";
import "./styles.scss";
import { IoSettingsSharp } from "react-icons/io5";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
} from "../../../../common/custom-field/dropstock-text";
import { FaChevronRight } from "react-icons/fa6";
import { DropworksSwitch } from "../../../../common/custom-field/dropworks-switch";
import Tabs from "../../../../common/tabs";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
import { Heading1, Heading2, Heading5 } from "../../../../common/custom-field/dropstock-heading";
import TabsComponent from "../../../../common/tabs-component/view";
import ArrowIcon from '../../../../assets/steps/Arrow -Selector.svg';


const view = () => {
  const [currentSection, setCurrentSection] = useState("Profile");



  const handleSectionClick = (section: string) => {
    setCurrentSection(section);
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value)
  }

  return (
    <div className="invite  container container-xl  container-xxl  min-height-cover  min-h-800">
      <Heading2 className="invite-heading uni-mb-32">
        Settings
      </Heading2>
      <div className="invite-button uni-mb-56 d-flex flex-wrap">

        <Tabs tabsTitle={["Profile", "Personal", "Connections", "Privacy", "Security"]} id="1"  checkTabState={checkTabState} />
      </div>

<TabsComponent tabsValue={tabsValue} components={[
  <>
        <div>
          <Heading5 className="uni-mb-56">General</Heading5>
          <ButtonComponent id="DisplayName">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Display Name</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="BiographyModal">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Biography</TextfieldMD>
             <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="UploadBanner">
            <div className="d-flex justify-content-between uni-mb-56" >
              <TextfieldMD className="mx-0 my-0 font-colour">Profile Picture</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="UploadBanner2">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Profile Banner</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="RolesModal">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Roles</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
        </div>
        <div>
          <h4 className="uni-mb-56">Artists</h4>
          <ButtonComponent id="DawsModal">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">DAW Preferences</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
         
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Display Releases</TextfieldMD>
              <DropworksSwitch className="switch-icon" />
            </div>
      
         
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Display Beats</TextfieldMD>
              <DropworksSwitch className="switch-icon" />

            </div>
      

        </div>
      </>,
      <>
        <div>
          <h4 className="uni-mb-56">General</h4>
          <ButtonComponent id="RegionModal">
            <div className="d-flex justify-content-between uni-mb-56"  >
              <TextfieldMD className="mx-0 my-0 font-colour">Country</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="DisplayLanguageModal">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Display Language</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="EmailModal">
            <div className="d-flex justify-content-between uni-mb-56" >
              <TextfieldMD className="mx-0 my-0 font-colour">Email</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="MobilePhoneModalA">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Mobile Phone</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
        </div>
      </>,
 <>
 <div>
   <h4 className="uni-mb-56">General</h4>
   <ButtonComponent id="SocialLinksMain">
     <div className="d-flex justify-content-between uni-mb-56">
       <TextfieldMD className="mx-0 my-0 font-colour">Social Links</TextfieldMD>
       <FaChevronRight className="icon-colour" />
     </div>
   </ButtonComponent>
 </div>
</>,
<>
        <div>
          <h4 className="uni-mb-56">General</h4>
        
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Receive Messages From Anyone</TextfieldMD>
              <DropworksSwitch className="switch-icon" />
            </div>
      
       
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Public Profile</TextfieldMD>
              <DropworksSwitch className="switch-icon" />
            </div>
          
    
            <div className="d-flex justify-content-between uni-mb-56" >
              <TextfieldMD className="mx-0 my-0 font-colour">Receive Offers From Anyone</TextfieldMD>
              <DropworksSwitch className="switch-icon" />
            </div>
          
          <ButtonComponent id="SocialLinksNotification">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Notifications</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
          <ButtonComponent id="BlockedUser1">
            <div className="d-flex justify-content-between uni-mb-56">
              <TextfieldMD className="mx-0 my-0 font-colour">Blocked Users</TextfieldMD>
              <img src={ArrowIcon} />
            </div>
          </ButtonComponent>
        </div>
      </>,
 <>
 <div>
   <h4 className="uni-mb-56">General</h4>
   <ButtonComponent id="ChangePassword">
     <div className="d-flex justify-content-between uni-mb-56">
       <TextfieldMD className="mx-0 my-0 font-colour">Password</TextfieldMD>
       <img src={ArrowIcon} />
     </div>
   </ButtonComponent>
   <ButtonComponent id="SecurityQuestion">
     <div className="d-flex justify-content-between uni-mb-56">
       <TextfieldMD className="mx-0 my-0 font-colour">Security Questions</TextfieldMD>
       <img src={ArrowIcon} />
     </div>
   </ButtonComponent>
   <ButtonComponent id="LoginHistorySocials">
     <div className="d-flex justify-content-between uni-mb-56" >
       <TextfieldMD className="mx-0 my-0 font-colour">Login History</TextfieldMD>
       <img src={ArrowIcon} />
     </div>
   </ButtonComponent>
 </div>
 <div>
   <h4 className="uni-mb-56">Advanced</h4>
   <ButtonComponent id="DeleteAccount">
     <div className="d-flex justify-content-between uni-mb-56">
       <TextfieldMD className="mx-0 my-0 font-colour">Delete Account</TextfieldMD>
       <img src={ArrowIcon} />
     </div>
   </ButtonComponent>
 </div>
</>
]}/>
    </div>
  );
};

export default view;
