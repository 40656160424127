import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';
import { DropworksSelect } from '../../../../../common/custom-field/dropworks-select';
import { SelectArrowDropdown } from '../../../../../common/selection-dropdown';
import { CloseModalButton } from '../../logo-header/view';

const view = () => {

  const options = [
    { value: "Security Question 1", label: "Security Question 1" },
    { value: "Security Question 2", label: "Security Question 2" },
    { value: "Security Question 3", label: "Security Question 3" },
    { value: "Security Question 4", label: "Security Question 4" },
    { value: "Security Question 5", label: "Security Question 5" },
    { value: "Security Question 6", label: "Security Question 6" },
    { value: "Security Question 7", label: "Security Question 7" },

  ];
  return (
    <div
    className="modal fade"
    id="SecurityQuestion"
    aria-hidden="true"
    aria-labelledby="SecurityQuestion"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Security Questions</h1>
        </div>
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">Secure your account further</TextfieldLG>
          <TextfieldXSM className='uni-mb-56 ds-ctm-txt'>Never share the answers with anyone</TextfieldXSM>
          <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue='Security Question'
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          <DropworksInput placeholder='Answer'></DropworksInput>
          <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue='Security Question'
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          <DropworksInput placeholder='Answer'></DropworksInput>
          <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue='Security Question'
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          <DropworksInput placeholder='Answer' className='uni-mb-56'></DropworksInput>
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <CloseModalButton>
          <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
          </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;
