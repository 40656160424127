import view from "./view";
import { Application } from '@splinetool/runtime';
import { splineArr } from './splineData'
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const LogoHeader=()=>{
    
    const dropAnimationRef:any = useRef(null);

    // useEffect(() => {
    //     const canvas:any = dropAnimationRef.current;
    //     const spline:any = new Application(canvas);
    //     spline.start(splineArr)
    // },[]);
    const navigate=useNavigate();
    
    const navigateTo = (value: string) => () => {
    navigate(value); 
  };
      return view({ dropAnimationRef, navigateTo });
}

export default LogoHeader;