import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
const view = () => {
  return (
    <div
      className="modal fade"
      id="SelectBeat"
      aria-hidden="true"
      aria-labelledby="SelectBeat"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content modal-width-inviteModal">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-40 ps-0 pt-0 pb-0 uni-pr-72">
            <h2 className="modal-title">Select the beat you want to report</h2>
          </div>
 
          <div className="modal-body uni-mb-56 ps-0 pt-0 pb-0 uni-pr-72"> 
            <div className='content-height'>

              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                     
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 select-title'>Hot Summer</TextfieldLG>
                <TextfieldSM className='my-0 select-subtitle p-0'>Unlost</TextfieldSM>
                </div>
                </div>
                <div>
                <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 select-title'>Hot Summer</TextfieldLG>
                <TextfieldSM className='my-0 select-subtitle p-0'>Unlost</TextfieldSM>
                </div>
                </div>
                <div>
                <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                     
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 select-title'>Hot Summer</TextfieldLG>
                <TextfieldSM className='my-0 select-subtitle p-0'>Unlost</TextfieldSM>
                </div>
                </div>
                <div>
                <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                     
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 select-title'>Hot Summer</TextfieldLG>
                <TextfieldSM className='my-0 select-subtitle p-0'>Unlost</TextfieldSM>
                </div>
                </div>
                <div>
                <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                     
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 select-title'>Hot Summer</TextfieldLG>
                <TextfieldSM className='my-0 select-subtitle p-0'>Unlost</TextfieldSM>
                </div>
                </div>
                <div>
                <DropworksRadioButton />
                </div>
              </div>   <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                     
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 select-title'>Hot Summer</TextfieldLG>
                <TextfieldSM className='my-0 select-subtitle p-0'>Unlost</TextfieldSM>
                </div>
                </div>
                <div>
                <DropworksRadioButton />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <ButtonComponent id="ReportModal1">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">Back</DropworksSmallButton>
            </ButtonComponent>
            <ButtonComponent id="BeatReportModal">
            <DropworksSmallButton className="btn-wid m-0">Next</DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default view;
 
 